import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import History from '../components/changeHistory';
import * as HistoryActions from '../actions/history';

class HistoryContainer extends React.Component {
    searchPlan = (obj) => {
        this.props.actions.getHistory(obj);
    }

    render() {
        const { planHistory } = this.props;
        return (
            <History searchPlan={this.searchPlan} planHistory={planHistory} />
        );
    }
}

function mapStateToProps(state) {
    return {
        planHistory: state.changeHistory.data
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(HistoryActions, dispatch),
    };
}

HistoryContainer.propTypes = {
    actions: PropTypes.object,
    planHistory: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryContainer);
