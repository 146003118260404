import { call, put } from 'redux-saga/effects';
import lookUpService from '../services/lookupsService';
import { setLookUps } from '../actions/lookups';

function* getLookups() {
    try {
        const lookups = yield call(lookUpService.getLookUps);
        yield put(setLookUps(lookups.data));
    } catch (err) {
        console.log(err);
    }
}

export default {
    getLookups,
};
