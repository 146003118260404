/* eslint-disable jsx-a11y/role-has-required-aria-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { includes, remove } from 'lodash';
import './style.scss';

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValues: props.value || null,
            isdropDownOpen: false,
            elementFocus: false,
        };
    }

    componentDidUpdate = (oldProps) => {
        if ((oldProps.value !== this.props.value)) {
            const value = (this.props.value) || null;
            this.setState({ selectedValues: value });
        }
    }

    handleChange = (option) => {
        const { multiSelect } = this.props;
        let { selectedValues } = this.state;
        if (multiSelect) {
            if (selectedValues === null) {
                selectedValues = [];
            }
            if (!includes(selectedValues.map((s) => s.value), option.value)) {
                selectedValues.push(option);
            } else {
                remove(selectedValues, ((elem) => elem.value === option.value));
            }
        } else {
            selectedValues = option;
        }
        this.setState({ selectedValues }, () => {
            if (!multiSelect) {
                this.handleDropdown();
            }
            this.props.onChange(selectedValues);
        });
    }

    handleDropdown = () => {
        const { disabled } = this.props;
        if (!disabled) {
            this.setState((prevState) => ({ isdropDownOpen: !prevState.isdropDownOpen }));
        }
    }

    handleMenu = () => {
        this.setState({ isdropDownOpen: false });
    }

    showValueLabel = () => {
        let label = this.props.placeholder;
        const { selectedValues } = this.state;
        const { multiSelect } = this.props;
        if (selectedValues) {
            if (Array.isArray(selectedValues) && selectedValues.length > 0 && multiSelect) {
                label = selectedValues.map((v) => v.label).join(', ');
            } else if (selectedValues && !Array.isArray(selectedValues)) {
                label = selectedValues.label;
            } else {
                label = this.props.placeholder;
            }
        }
        return label;
    }

    onBlur = () => {
        if (!this.state.elementFocus) {
            this.handleMenu();
        }
    }

    render() {
        const { options, multiSelect, disabled } = this.props;
        const { isdropDownOpen, selectedValues } = this.state;
        return (
            <div
                className="dropdown-container form-control"
                onBlur={this.onBlur}
                onMouseEnter={() => { this.setState({ elementFocus: true }); }}
                onMouseOver={() => { this.setState({ elementFocus: true }); }}
                onFocus={() => { this.setState({ elementFocus: true }); }}
                onMouseOut={() => { this.setState({ elementFocus: false }); }}
            >
                <div className={`dropdown_label ${isdropDownOpen ? 'open' : ''} ${disabled ? 'disabled' : ''}`} role="button" tabIndex="0" onClick={this.handleDropdown}>{ this.showValueLabel() }</div>
                {!disabled && isdropDownOpen ? (
                    <ul tabIndex="0" role="listbox">
                        {options.reduce((elm, option) => [
                            ...elm, (
                                <li role="option" key={`Preset_Columns_for_${option.value}`} className={!multiSelect ? 'radioList' : ''}>
                                    {!multiSelect ? (
                                        <input
                                            id={`dropdown_${option.value}`}
                                            type="radio"
                                            className="radio light float-left selectRadio"
                                            name="radiobtn"
                                            onChange={() => { this.handleChange(option); }}
                                            checked={(selectedValues && selectedValues.value === option.value) || false}
                                        />
                                    ) : (
                                        <input
                                            type="checkbox"
                                            id={`dropdown_${option.value}`}
                                            className="checkbox light float-left"
                                            checked={selectedValues && selectedValues.length > 0 && includes(selectedValues.map((s) => s.value), option.value)}
                                            onChange={() => { this.handleChange(option); }}
                                        />
                                    )}
                                    <label htmlFor={`dropdown_${option.value}`}>{option.label}</label>
                                </li>
                            ),
                        ], [])}
                    </ul>
                ) : null}
            </div>
        );
    }
}

Dropdown.defaultProps = {
    placeholder: 'Select',
    multiSelect: false,
    value: null,
    onChange: () => {},
    disabled: false,
};

Dropdown.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.any.isRequired,
        label: PropTypes.string.isRequired,
    })),
    multiSelect: PropTypes.bool,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
};

export default Dropdown;
