
import { AgGridReact } from 'ag-grid-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';
import update from 'immutability-helper';
import GRIDCOLUMNS from '../../../config/gridConfig';
import utils from '../../../util/editorUtils';
import CustomDateComponent from './customDateComponent';
import './style.scss';

class AgGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            frameworkComponents: { datePicker: CustomDateComponent },
        };
    }

    onGridReady = (params) => {
        const { gridPreference } = this.props;
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
		const preference = gridPreference && gridPreference.preference ? JSON.parse(window.atob(gridPreference.preference)) : null;
		const columnDefs = preference ? preference : GRIDCOLUMNS.slice();
        this.setColumnDefinition(columnDefs);
    }

    setColumnDefinition(columnDefs) {
        const { editableColumns } = this.props;
        const columns = columnDefs.map((column) => {
			const defaultColumn = GRIDCOLUMNS.find((c) => c.colId === column.colId);
            // get editable column labels and editor cells
            if (defaultColumn && defaultColumn.editable !== undefined) {
                const index = editableColumns.findIndex((col) => col.name === column.field);

                if (index !== -1) {
					// make sure the editableColumns are not removeable
					defaultColumn.lockVisible = true;
                    const { additionalInfo } = editableColumns[index];
                    defaultColumn.headerName = editableColumns[index].label;
                    // Check whether user can edit the field
                    if (this.isColumnRolesMatchUserRole(additionalInfo.roles)) {
						defaultColumn.editable = true;
                        defaultColumn.headerClass = 'highlightClass';
                        defaultColumn.cellClassRules = {
                            cellhighlightClass: (params) => {
                                const { rowData } = this.props;
                                let classFlag = false;
                                if (params.value) {
                                    const rowIndex = rowData.findIndex((e) => e.product.productId === params.node.data.product.productId);
                                    if (rowIndex !== -1) {
                                        if (rowData[rowIndex][params.colDef.colId] === undefined || this.props.rowData[rowIndex][params.colDef.colId] !== params.value) {
                                            classFlag = true;
                                        }
                                    }
                                }
                                return classFlag;
                            },
                        };
                        if (editableColumns[index].type) {
                            utils.getEditorCell(column, editableColumns[index]);
                        }
                    }
                }
            }
			if (column.cellClassRules && !column.cellClassRules.cellhighlightClass) {
				delete column.cellClassRules;
			}
			column = { ...defaultColumn, ...column };
            return column;
        });
		this.gridApi.setColumnDefs([]);
        this.gridApi.setColumnDefs(columns);
    }


    handleCellEditing = (params) => {
        if (params.value && params.oldValue !== params.newValue) {
            this.editData(params);
        }
    }

    isRowEdited = () => ({
        rowhighlightClass: (params) => {
			const { rowData } = this.props;
			if (params.node.data) {
				const rowIndex = rowData.findIndex((e) => e.poID === params.node.data.poID);
				// console.log(params.node.data.product.productId, JSON.stringify(rowData[rowIndex]), JSON.stringify(params.node.data));
				if (!_.isEqual(rowData[rowIndex], params.node.data)) {
					return true;
				}
			}
            return false;
        },
    })

    editData = (params) => {
        const { editedData } = this.props;
        const rowIndex = editedData.findIndex((row) => row.poID === params.node.data.poID);
        if (rowIndex !== -1) {
            const updatedRow = update(editedData[rowIndex], { $set: params.node.data });
            const newData = update(editedData, {
                $splice: [
                    [rowIndex, 1, updatedRow],
                ],
            });
            this.props.setEditedData(newData);
        } else {
            this.props.setEditedData(editedData.concat(params.node.data));
        }
    }

    isColumnRolesMatchUserRole(columnRoles) {
        const { user, roles } = this.props;
        const userRoles = process.env.REACT_APP_ENV === 'prod' ? ['app.nikebyyou.admin', 'app.nikebyyou.digital', 'app.nikebyyou.merch', 'app.nikebyyou.operations', 'app.nikebyyou.readonly'] : ['app.nikebyyou.admin.preprod', 'app.nikebyyou.digital.preprod', 'app.nikebyyou.merch.preprod', 'app.nikebyyou.operations.preprod', 'app.nikebyyou.readonly.preprod'];
        const userRole = userRoles.reduce((role, r) => {
            if (role === null && user && user.groups && user.groups.findIndex((g) => g.toLowerCase() === r) !== -1) {
                role = r;
                return role;
            }
            return role;
        }, null);
        if (userRole !== null) {
            const roleId = roles.reduce((role, r) => {
                if (role === null && userRole.indexOf(r.adGroup.toLowerCase()) !== -1) {
                    role = r.id;
                }
                return role;
            }, null);
            if (roleId !== null && columnRoles.indexOf(roleId) !== -1) {
                return true;
            }
            return false;
        }
        return false;
    }

	getCurrentColumnState = () => {
        const columnState = this.columnApi.getColumnState();
        const sortModel = this.columnApi.getColumnState();
        const groupedColumns = this.columnApi.getColumnGroupState();
        const sortedColumnsIds = sortModel.map(sortedColumn => {
            return sortedColumn.colId;
        });
        const updatedColumnDefs = [];
        const defaultColumnDefs = GRIDCOLUMNS.slice();
        columnState.forEach((column) => {
			const defaultColumn = defaultColumnDefs.find((c) => c.colId === column.colId);
			if (defaultColumn) {
				let updatedColumn = { ...defaultColumn, ...column };
				if (sortedColumnsIds && sortedColumnsIds.includes(updatedColumn.colId)) {
					const sortedColumn = _.find(sortModel, (sortedCol) => {return sortedCol.colId === updatedColumn.colId;});
					updatedColumn = { ...updatedColumn, ...sortedColumn };
				} else {
					updatedColumn.sort = null;
				}
				groupedColumns.forEach((groupedColumn) => {
					if (groupedColumn.colId === column.colId) {
						updatedColumn = Object.assign({}, updatedColumn, groupedColumn);
					}
				});
				updatedColumnDefs.push(updatedColumn);
			}
        });
        return updatedColumnDefs;
    }

	/**
     *To save the property to user preference.
     */
    saveGridStateToPreference = () => {
        const updatedColumnDefs = this.getCurrentColumnState();
        this.props.updateGridPreference(updatedColumnDefs);
    }

	restoreToDefault = () => {
		this.props.updateGridPreference(GRIDCOLUMNS);
		this.setColumnDefinition(GRIDCOLUMNS.slice());
	}

	processDataFromClipboard = (params) => {
		const dateColumnsToBeFormated = ['flexfield9', 'flexfield10', 'flexfield11', 'flexfield12', 'flexfield16', 'flexfield21', 'flexfield22', 'flexfield28'];
		if (_.includes(dateColumnsToBeFormated, params.column.colDef.colId)) {
			if (params.value !== '' && !isNaN(Date.parse(params.value))) {
				return moment(new Date(params.value)).format('MM/DD/YYYY');
			} else if (params.node.data[params.column.colDef.colId] !== undefined && params.node.data[params.column.colDef.colId] !== null && params.node.data[params.column.colDef.colId] !== '' && moment(params.node.data[params.column.colDef.colId]) instanceof moment) {
				return params.node.data[params.column.colDef.colId];
			} else {
				return null;
			}
		}
		return params.value;
	}

	getRowNodeId = (data) => {
        return data.poID;
    }

    render() {
        return (
            <div
                className="ag-theme-balham planGrid"
            >
				<span className="bi bi-arrow-repeat" onClick={this.restoreToDefault} title="Restore Grid Default Settings" />
                <AgGridReact
                    rowData={_.cloneDeep(this.props.rowData)}
                    // rowClassRules={this.isRowEdited()}
                    defaultColDef={{ resizable: true, sortable: true, filter: 'agTextColumnFilter' }}
                    frameworkComponents={this.state.frameworkComponents}
                    groupDisplayType='groupRows'
                    onGridReady={this.onGridReady}
                    onCellValueChanged={this.handleCellEditing}
					onSortChanged={this.saveGridStateToPreference}
					onColumnPinned={this.saveGridStateToPreference}
					onColumnRowGroupChanged={this.saveGridStateToPreference}
					onColumnMoved={this.saveGridStateToPreference}
					onColumnResized={(column) => { if(column.finished) { this.saveGridStateToPreference(); } }}
					onColumnVisible={this.saveGridStateToPreference}
					rowGroupPanelShow={'always'}
					enableRangeSelection
					suppressDragLeaveHidesColumns
					stopEditingWhenCellsLoseFocus
					enableFillHandle
					processCellFromClipboard={this.processDataFromClipboard}
					// deltaRowDataMode
					getRowNodeId={this.getRowNodeId}
					// rowModelType={'serverSide'}
                />
            </div>
        );
    }
}

AgGrid.propTypes = {
    rowData: PropTypes.array,
    editableColumns: PropTypes.array,
    editedData: PropTypes.array,
    setEditedData: PropTypes.func,
    user: PropTypes.object,
    roles: PropTypes.array,
};

export default AgGrid;
