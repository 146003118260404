/* eslint-disable no-restricted-syntax */
import { takeEvery } from 'redux-saga/effects';

import * as lookupsActions from '../actions/lookups';
import * as lookupsSagas from './lookups';
import * as planActions from '../actions/planning';
import * as planSagas from './planning';
import * as calendarActions from '../actions/calendarReport';
import * as calendarSagas from './calendarReport';
import * as preferenceActions from '../actions/preference';
import * as preferenceSagas from './preference';

import * as historyActions from '../actions/history';
import * as historySagas from './history';
import * as prodReportActions from '../actions/productionReport';
import * as prodReportSagas from './productionReport';
import * as userActions from '../actions/user';
import * as userSagas from './user';

export default function* saga() {
    const relations = [
        [lookupsActions, lookupsSagas],
        [planActions, planSagas],
        [calendarActions, calendarSagas],
        [historyActions, historySagas],
        [userActions, userSagas],
        [prodReportActions, prodReportSagas],
        [preferenceActions, preferenceSagas],
    ];

    for (const [actions, sagas] of relations) {
        for (const [actionName, action] of Object.entries(actions)) {
            const actionSaga = sagas.default[actionName];
            if (actionSaga) yield takeEvery(action.getType(), actionSaga);
        }
    }
}
