import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as calendarActions from '../actions/calendarReport';
import * as preferenceActions from '../actions/preference';
import CalenderReport from '../components/calendarReport';

class CalendarContainer extends React.Component {
    getReports = (code) => {
        this.props.calendarActions.getCalendarReports(code);
    }

	updateGridPreference = (columnDefs) => {
		const requestBody = {
			gridType: 'CALENDAR_REPORT',
			preference: window.btoa(JSON.stringify(columnDefs)),
		};
		if (this.props.gridPreference) {
			this.props.preferenceActions.updateGridPreference({ preferenceId: this.props.gridPreference.id, requestBody });
		} else {
			this.props.preferenceActions.insertGridPreference({ requestBody });
		}
	}

    render() {
        const { reports, editableColumns, seasons } = this.props;
        return (
            <CalenderReport
				getReports={this.getReports}
				reports={reports}
				editableColumns={editableColumns}
				seasons={seasons}
				gridPreference={this.props.gridPreference}
				updateGridPreference={this.updateGridPreference}
			/>
        );
    }
}


function mapStateToProps(state) {
    return {
        reports: state.calendarReport.data,
        editableColumns: state.lookups.COLUMNS || [],
        seasons: state.lookups.SEASON_YEAR_ID || [],
		gridPreference: state.preference.grid.find((gridPreference) => gridPreference.gridType.toUpperCase() === 'CALENDAR_REPORT') || null
    };
}


function mapDispatchToProps(dispatch) {
    return {
        calendarActions: bindActionCreators(calendarActions, dispatch),
		preferenceActions: bindActionCreators(preferenceActions, dispatch),
    };
}

CalendarContainer.propTypes = {
    calendarActions: PropTypes.object,
    reports: PropTypes.array,
    editableColumns: PropTypes.array,
    seasons: PropTypes.array,
	gridPreference: PropTypes.object,
	preferenceActions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarContainer);
