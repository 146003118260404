/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

export default function Accordion({
    isOpen,
    title,
    children,
    isMandatory,
    maxHeight,
    contentOverflow,
    isValid,
}) {
    const [open, setOpen] = useState(isOpen || false);
    useEffect(() => {
        if (!isValid && isMandatory) setOpen(true);
    }, [isValid, isMandatory]);
    const contentStyle = {};
    if (maxHeight) {
        contentStyle.maxHeight = `${maxHeight}px`;
    }
    return (
        <div className={`accordion ${open ? 'open' : ''}`}>
            <div className={`header${(!isValid && isMandatory) ? ' notValid' : ''}`}>
                <span onClick={() => setOpen(!open)}>
                    {title}
                    {isMandatory ? <span className="mandatory">*</span> : null}
                    {!isValid && isMandatory ? <span className="validationMessage">selection is required</span> : null}
                </span>
                <span onClick={() => setOpen(!open)} className={`toggleIcon float-right ${open ? 'open' : ''}`} />
                <span className="clearfix" />
            </div>
            <div className={`content ${open ? 'open' : ''} ${contentOverflow ? 'overflowInherit' : ''}`} style={contentStyle}>
                {children}
            </div>
            <div className="borderLine" />
        </div>
    );
}

Accordion.defaultProps = {
    isOpen: false,
    title: 'Accordion',
    isMandatory: false,
    contentOverflow: false,
    isValid: true,
};
Accordion.propTypes = {
    children: PropTypes.any,
    title: PropTypes.any,
    isOpen: PropTypes.bool,
    isMandatory: PropTypes.bool,
    maxHeight: PropTypes.number,
    contentOverflow: PropTypes.bool,
    isValid: PropTypes.bool,
};
