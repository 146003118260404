import { call, put } from 'redux-saga/effects';
import calenderReportService from '../services/calenderReportService';
import { setCalendarReports } from '../actions/calendarReport';

function* getCalendarReports(data) {
    try {
        const res = yield call(calenderReportService.getCalendarReports, data.payload);
        if (res && res.data) {
            yield put(setCalendarReports(res.data));
        }
    } catch (e) {
        console.log(e);
    }
}

export default {
    getCalendarReports,
};
