import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import GRIDCOLUMNS from '../../../config/productionReport';

class Grid extends Component {
    formatDate = (date) => {
        // Convert date format DD/MM/YYYY to YYYY-MM-DD
        const dateSplit = date.split('/');

        // COnvert format to dd-mmm-yyyy
        return new Date(parseInt(dateSplit[2], 10), parseInt(dateSplit[1], 10) - 1, parseInt(dateSplit[0], 10)).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, '-');
    }

    getActionValue = (action) => {
        const obj = {
            flexfield11: 'DROP',
            flexfield9: 'LAUNCH',
            flexfield16: 'UPDATE',
        };
        return obj[action];
    }

    render() {
        const { data } = this.props;
        return (
            <Table responsive hover>
                <thead>
                    <tr>
                        {GRIDCOLUMNS.map((column) => (
                            <th key={`digitalProduction-${column.headerName}`}>{column.headerName}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className="text-center">
                    {data.map((childData, key) => (
                        <tr key={key}>
                            <td>{this.formatDate(childData.newData[childData.action])}</td>
                            <td>{this.getActionValue(childData.action)}</td>
                            <td>{this.formatDate(childData.weekStartDate)}</td>
                            <td>-</td>
                            <td>{childData.model && childData.model.modelName ? childData.model.modelName : '-'}</td>
                            <td>{childData.modelOffering && childData.modelOffering.categoryProductLineManagerUserAccountCode ? childData.modelOffering.categoryProductLineManagerUserAccountCode : '-'}</td>
                            <td>{childData.categoryProductLineManagerName ? childData.categoryProductLineManagerName : '-'}</td>
                            <td> - </td>
                            <td> - </td>
                            <td>{childData.owner && childData.owner.businessOrganizationDescription ? childData.owner.businessOrganizationDescription : ''}</td>
                            <td>{childData.modelOffering && childData.modelOffering.marketingName ? childData.modelOffering.marketingName : '-'}</td>
                            <td>{childData.product && childData.product.productCode ? childData.product.productCode : '-'}</td>
                            <td>{childData.newData && childData.newData.flexfield28 ? this.formatDate(childData.newData.flexfield28) : '-'}</td>
                            <td>{childData.product && childData.product.siloDescription ? childData.product.siloDescription : '-'}</td>
                            <td>{childData.modelOffering && childData.modelOffering.moGroup && childData.modelOffering.moGroup.length > 0 ? childData.modelOffering.moGroup.map((mg) => mg.modelOfferingGroupDescription).join(', ') : '-'}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }
}


Grid.propTypes = {
    data: PropTypes.array,
    week: PropTypes.string,
};

export default Grid;
