/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '../shared';
import AccordionTitle from './grid/accordionTitle';
import './style.scss';
import Grid from './grid';


class ProductionReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTable: true,
        };
    }

    render() {
        const { finalData } = this.props;
        return (
            this.state.showTable && (
                <div className="reportContainer">
                    {finalData && Object.keys(finalData).length > 0 ? Object.keys(finalData).map((weekend) => (
                        <Accordion key={`weekEnd-Date:${weekend}`} title={<AccordionTitle weekend={weekend} />}>
                            <Grid data={finalData[weekend]} week={weekend} />
                        </Accordion>
                    )) : <div className="nodata no-gutters row text-center"><span className="col-12">No records found for search criteria</span></div>}
                </div>
            )
        );
    }
}


ProductionReport.propTypes = {
    finalData: PropTypes.object,
};


export default ProductionReport;
