import { call, put } from 'redux-saga/effects';
import prodReportService from '../services/prodReportService';
import { setReportData } from '../actions/productionReport';

function* getReportData(data) {
    try {
        const res = yield call(prodReportService.getReportData, data.payload);
        if (res && res.data) {
            yield put(setReportData(res.data));
        } else {
            yield put(setReportData({}));
        }
    } catch (e) {
        yield put(setReportData({}));
        console.log(e);
    }
}

export default {
    getReportData,
};
