import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

class FreeTextField extends Component {
    handleChange = (name, e) => {
        let { value } = e.target;
        if (value.length) value = value.split(',');
        this.validateAndUpdate(name, value);
    }

    validateAndUpdate = (name, value) => {
        const { onTextChange } = this.props;
        if (name === 'productCode') {
            if (/^[\da-zA-Z,-\s]+$/.test(value) || value === '') {
                onTextChange(value);
            }
        } else if (name === 'styleNumber') {
            if (/^[\da-zA-Z,_\s]+$/.test(value) || value === '') {
                onTextChange(value);
            }
        } else {
            onTextChange(value);
        }
    }

    render() {
        const {
            name,
            value,
            hint,
        } = this.props;
        return (
            <div className="freeTextField">
                <input
                    type="text"
                    name={name}
                    value={value || ''}
                    placeholder={`Enter ${hint}`}
                    onChange={(e) => this.handleChange(name, e)}
                />
            </div>
        );
    }
}
FreeTextField.defaultProps = {
    hint: '',
};
FreeTextField.propTypes = {
    value: PropTypes.arrayOf(PropTypes.object),
    onTextChange: PropTypes.func.isRequired,
    hint: PropTypes.string,
    name: PropTypes.string,
};
export default FreeTextField;
