import React from 'react';
import { Route, Switch } from 'react-router';
import { Router } from 'react-router-dom';
import {
    Security,
    SecureRoute,
    LoginCallback
} from '@okta/okta-react';
// import { ConnectedRouter } from 'connected-react-router';
import PlanContainer from './containers/plan';
import HistoryContainer from './containers/changeHistory';
import CalendarContainer from './containers/calenderReport';
import ProductionReportContainer from './containers/productionReport';
import history from './history';
import SetupAxios from './containers/setupAxios';
import { axiosInstance } from './services/httpService';
import PATH from './constants/routing';
import Base from './containers/base';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_JWT_ISSUER,
    clientId: process.env.REACT_APP_JWT_CLIENT_ID,
    scopes: ['default', 'openid'],
    grantType: 'authorization_code',
    pkce: true,
    responseMode: 'fragment',
    disableHttpsCheck: true,
    redirectUri: window.location.origin + '/OKTA_Callback'
});

const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
};

export default () => (
    <Router history={history}>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <SetupAxios axios={axiosInstance}>
                <Switch>
                    {/* <Route exact path={PATH.AUTH} component={Auth} /> */}
                    <Route path='/OKTA_Callback' component={LoginCallback} />
                    <Base>
                        <SecureRoute exact path={PATH.PLAN} component={PlanContainer} />
                        <SecureRoute exact path={PATH.HISTORY} component={HistoryContainer} />
                        <SecureRoute exact path={PATH.CALENDAR_REPORT} component={CalendarContainer} />
                        <SecureRoute exact path={PATH.PRODUCTION_REPORT} component={ProductionReportContainer} />
                    </Base>
                    {/* <Route component={Home} /> */}
                </Switch>
            </SetupAxios>
        </Security>
    </Router>
);
