/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

export default function AccordionTitle(props) {
    const { data } = props;
    return (
        <span className="row col-md-12">
            <span className="col-md-4">{data[0].modelOffering.marketingName}</span>
            <span className="col-md-5">{new Date(data[0].updatedDate).toDateString().slice(4)}</span>
        </span>
    );
}

AccordionTitle.propTypes = {
    data: PropTypes.array,
};
