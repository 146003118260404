import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { groupBy } from 'lodash';
import PlanLayout from '../../components/planLayout';
import * as PlanActions from '../../actions/planning';
import * as PreferenceActions from '../../actions/preference';

class PlanContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { editedData: [], syncTime: null };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.planData !== this.props.planData) {
            this.setState({ editedData: [] });
        }
    }

    getPlans = (code) => {
        this.props.planActions.getPlans(code);
    }

    setEditedData = (editedData) => {
        // this.setState({ editedData });
        this.setState(prevState => ({ 
            editedData: [...new Map([...prevState.editedData, ...editedData].map(item => [item.poID, item])).values()]
        }));
    }

    saveEditedData = () => {
        let { editedData } = this.state;
        if (editedData.length !== 0) {
            editedData = editedData.map((d) => {
                const updatedObj = this.props.editableColumns.reduce((obj, col) => {
                    if (d[col.name]) {
                        obj[col.name] = d[col.name];
                    }
                    return obj;
                }, {});
                updatedObj.productId = d.product.productId;
                // updatedObj.season = d.modelOffering.seasonYearAbbreviation;
                return updatedObj;
            });
            this.props.planActions.updatePlan(editedData);
        }
    }

    setSyncDateToNow = () => {
        this.setState({ syncTime: Date.now() });
    }

    syncFlexFields = () => {
        const { planData, editableColumns } = this.props;
        const { syncTime } = this.state;
        const syncDate = new Date(syncTime);
        // flex fields to be in sync
        const flexFields = [{
            field: 'flexfield27',
            node: 'modelId',
        }, {
            field: 'flexfield25',
            node: 'modelOfferingIdentifier',
        }, {
            field: 'flexfield24',
            node: 'modelOfferingIdentifier',
        }];
        let editedData = [];
        // get the edited rows which is edited after the sync time
        const editedRows = planData.map((p) => {
            if (p.updatedDate) {
                const updatedDate = new Date(p.updatedDate);
                if (Date.parse(updatedDate) >= Date.parse(syncDate) && (p.flexfield27 || p.flexfield25 || p.flexfield24)) {
                    return p;
                }
            }
            return null;
        }).filter((p) => p !== null);
        flexFields.forEach((flexField) => {
            // group the edited rows based on the node
            const groups = groupBy(editedRows, (r) => r.modelOffering[flexField.node]);
            editedData = Object.keys(groups).reduce((eData, group) => {
                // master row which is used to get the flex field value to update
                let masterRow = null;
                const rows = [...groups[group]].filter((r) => r[flexField.field] && r.updatedDate);
                if (rows.length > 0) {
                    // if one row that is the master row
                    if (rows.length === 1) {
                        [masterRow] = rows;
                    } else { // more than one get the latest edited row and that will be the master
                        masterRow = rows.reduce((m, row) => {
                            if (m === null || (Date.parse(m.updatedDate) < Date.parse(row.updatedDate))) {
                                m = row;
                            }
                            return m;
                        }, null);
                    }
                    eData = planData.map((p) => {
                        if (p.modelOffering[flexField.node] === masterRow.modelOffering[flexField.node] && masterRow.product.productId !== p.product.productId) {
                            const alreadyEditedData = eData.find((d) => d.productId === p.product.productId);
                            const object = alreadyEditedData || {};
                            const updatedObj = editableColumns.reduce((obj, col) => {
                                if ((col.name === 'flexfield27' && obj.flexfield27) || (col.name === 'flexfield24' && obj.flexfield24) || (col.name === 'flexfield25' && obj.flexfield25)) {
                                    return obj;
                                }
                                if (p[col.name]) {
                                    obj[col.name] = p[col.name];
                                }
                                return obj;
                            }, object);
                            updatedObj[flexField.field] = masterRow[flexField.field];
                            updatedObj.productId = p.product.productId;
                            updatedObj.season = p.seasonYearAbbreviation;
                            return updatedObj;
                        }
                        return null;
                    }).filter((d) => d !== null);
                    return eData;
                }
                return eData;
            }, editedData);
        });
        if (editedData.length > 0) {
			this.setState({ syncTime: null }, () => {
            	this.props.planActions.updatePlan(editedData);
			});
        }
    }

	updateGridPreference = (columnDefs) => {
		const requestBody = {
			gridType: 'PLAN',
			preference: window.btoa(JSON.stringify(columnDefs)),
		};
		if (this.props.gridPreference) {
			this.props.preferenceActions.updateGridPreference({ preferenceId: this.props.gridPreference.id, requestBody });
		} else {
			this.props.preferenceActions.insertGridPreference({ requestBody });
		}
	}

    render() {
        return (
            <PlanLayout
                getPlan={this.getPlans}
                saveEditedData={this.saveEditedData}
                editedData={this.state.editedData}
                setEditedData={this.setEditedData}
                planData={this.props.planData}
                editableColumns={this.props.editableColumns}
                user={this.props.user}
                seasons={this.props.seasons}
                roles={this.props.roles}
                setSyncDateToNow={this.setSyncDateToNow}
                syncTime={this.state.syncTime}
                syncFlexFields={this.syncFlexFields}
				gridPreference={this.props.gridPreference}
				updateGridPreference={this.updateGridPreference}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        planData: state.plan.data,
        editableColumns: state.lookups.COLUMNS || [],
        user: state.user,
        seasons: state.lookups.SEASON_YEAR_ID || [],
        roles: state.lookups.ROLE_DETAILS || [],
		gridPreference: state.preference.grid.find((gridPreference) => gridPreference.gridType.toUpperCase() === 'PLAN') || null
    };
}


function mapDispatchToProps(dispatch) {
    return {
        planActions: bindActionCreators(PlanActions, dispatch),
		preferenceActions: bindActionCreators(PreferenceActions, dispatch),
    };
}

PlanContainer.propTypes = {
    planActions: PropTypes.object,
    planData: PropTypes.array,
    editableColumns: PropTypes.array,
    user: PropTypes.object,
    seasons: PropTypes.array,
    roles: PropTypes.array,
	gridPreference: PropTypes.object,
	preferenceActions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanContainer);
