/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

export default function AccordionTitle(props) {
    const { weekend } = props;
    const dateSplit = weekend.split('/');
    return (
        <span className="row col-md-12">
            <span className="col-md-5">{`Week : ${new Date(parseInt(dateSplit[2], 10), parseInt(dateSplit[1], 10) - 1, parseInt(dateSplit[0], 10)).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' }).replace(/ /g, '-')}`}</span>
        </span>
    );
}

AccordionTitle.propTypes = {
    weekend: PropTypes.string,
};
