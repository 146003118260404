import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import { withOktaAuth } from '@okta/okta-react';
import { Header, Loader } from '../../components/common';
import { getLookups } from '../../actions/lookups';
import { getUser } from '../../actions/user';
import { getPreference } from '../../actions/preference';

import './index.scss';

class Base extends React.Component {
    constructor(props) {
        super(props);
        this.state = { token: null };
        this.checkAuthentication = this.checkAuthentication.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
    }

    async componentDidMount() {
        await this.checkAuthentication();
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if (!this.state.token && nextState.token) {
            this.props.dispatch(getUser());
            this.props.dispatch(getLookups());
            this.props.dispatch(getPreference());
        }
    }

    async checkAuthentication() {
        const token = await this.props.oktaAuth.getAccessToken();
        const authenticated = await this.props.oktaAuth.isAuthenticated();
        if (!authenticated && (token === null || token === undefined || token === '')) {
            this.login();
        } 
        this.setState({ token });
    }

    login() {
        this.props.oktaAuth.signInWithRedirect();
    }

    logout() {
        this.props.oktaAuth.signOut('/');;
    }

    render() {
        const { token } = this.state;
        const { lookups, user } = this.props;
        return token ? (
            <React.Fragment>
                {this.props.activeRequestsCount > 0 ? <Loader /> : null}
                {
                    // eslint-disable-next-line no-nested-ternary
                    lookups ? (
                        user && user.groups && user.groups.length > 0 ? (
                            <React.Fragment>
                                <Header user={user} />
                                <div className="mainContent">
                                    { this.props.children }
                                </div>
                            </React.Fragment>
                        ) : (
                            <div className="noAccess">
                                Sorry you don&apos;t have access right now, Please contact the administrator.
                            </div>
                        )
                    ) : null
                }
                <NotificationContainer />
            </React.Fragment>
        ) : null;
    }
}

function mapStateToProps(state) {
    return {
        reports: state.reports,
        lookups: state.lookups,
        user: state.user,
        activeRequestsCount: state.network.activeRequestsCount,
    };
}
Base.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
    ]).isRequired,
    activeRequestsCount: PropTypes.number,
    lookups: PropTypes.object,
};

Base.propTypes = {
    dispatch: PropTypes.func,
    oktaAuth: PropTypes.object,
    user: PropTypes.object,
};

export default connect(mapStateToProps)(withOktaAuth(Base));
