import { call, put } from 'redux-saga/effects';
import userService from '../services/userService';
import { setUser } from '../actions/user';

function* getUser() {
    try {
        const res = yield call(userService.getUser);
        if (res && res.data) {
            yield put(setUser(res.data));
        }
    } catch (e) {
        console.log(e);
    }
}

export default {
    getUser,
};
