
const log = (tag, object) => {
    if (window.newrelic) {
        window.newrelic.addPageAction(tag,
            {
                ...object,
                username: window.localStoragerror.getItem('username'),
                time: new Date().toString(),
            });
    }
};

const reportError = (error) => {
    if (process.env.NEWRELIC_KEY) {
        log('errorInfo', {
            action: 'JSError',
            errorMsg: error.message,
            url: error.filename,
            line: error.lineno,
            error: error.error.message,
            stack: error.error.stack,
        });
        window.newrelic.noticeError(error);
    } else {
    // eslint-disable-next-line no-console
        console.error(error);
    }
};
const reportUserAction = (action) => {
    if (process.env.NEWRELIC_KEY) {
        log('userAction',
            {
                action,
            });
    } else {
        console.error(action);
    }
};
const reportAPIResponse = (action, response) => {
    if (process.env.NEWRELIC_KEY) {
        log('apiResponse',
            {
                action,
                url: response.url,
                status: response.status,
            });
    } else {
    // eslint-disable-next-line no-console
        console.error(action);
    }
};
export default {
    reportError,
    reportUserAction,
    reportAPIResponse,
};
