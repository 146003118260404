import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import logger from '../../services/logging';
import { IncrementRequestCount, DecrementRequestCount, AddAPIMessages } from '../../actions/network';

export class SetupAxios extends React.Component {
    requestInterceptor = null;

    responseInterceptor = null;

    componentDidMount() {
        this.requestInterceptor = this.props.axios.interceptors.request.use(
            this.requestInterceptorSuccessHandler,
            this.requestInterceptorErrorHandler,
        );
        this.responseInterceptor = this.props.axios.interceptors.response.use(
            this.responseInterceptorSuccessHandler,
            this.responseInterceptorErrorHandler,
        );
    }

    componentWillUnmount() {
        this.props.axios.interceptors.request.eject(this.requestInterceptor);
        this.props.axios.interceptors.response.eject(this.responseInterceptor);
    }

    requestInterceptorSuccessHandler = async (config) => {
        const token = await this.props.oktaAuth.getAccessToken();
        // If not able to retrieve a token, send the user back to login
        if (typeof token === 'undefined') {
            // this.props.authService.login(`${this.props.location.pathname}${this.props.location.search}${this.props.location.hash}`);
            this.props.oktaAuth.signInWithRedirect();
            return config;
        }
        // Process the user supplied requestInterceptorHandler
        const newConfig = this.props.requestInterceptorHandler(config);

        // increment the request count in store
		if (newConfig.url.indexOf('preference') === -1) {
        	this.props.dispatch(new IncrementRequestCount());
		}

        // Return the config with the token appended to the Authorization Header
        return {
            ...newConfig,
            headers: {
                ...get(newConfig, 'headers', {}),
                Authorization: `Bearer ${token}`,
            },
        };
    };

    requestInterceptorErrorHandler = (error) => this.props.requestInterceptorErrorHandler(error);

    responseInterceptorSuccessHandler = (response) => {
        this.props.responseInterceptorSuccessHandler(response);

        // decrease the request count in store
		if (response.config.url.indexOf('preference') === -1) {
        	this.props.dispatch(new DecrementRequestCount());
		}

        return response;
    }

    responseInterceptorErrorHandler = (error) => {
        const expectedError = error.reponse
            && error.response.status >= 400
            && error.response.status <= 500;

        if (!expectedError) {
            logger.reportError(error);
            const message = error.message ? error.message : 'API Error';
            NotificationManager.error(message, 'Error', 5000);
            this.props.dispatch(new AddAPIMessages({ type: 'error', message }));
        }
        // decrease the request count in store
        this.props.dispatch(new DecrementRequestCount());
        if (get(error, 'response.status') === 401) {
            // this.props.authService.login(`${this.props.location.pathname}${this.props.location.search}${this.props.location.hash}`);
            this.props.oktaAuth.signInWithRedirect();
        }

        return this.props.responseInterceptorErrorHandler(error);
    };

    render() {
        return this.props.children;
    }
}

SetupAxios.propTypes = {
    oktaAuth: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    axios: PropTypes.func,
    children: PropTypes.node,
    requestInterceptorHandler: PropTypes.func,
    requestInterceptorErrorHandler: PropTypes.func,
    responseInterceptorSuccessHandler: PropTypes.func,
    responseInterceptorErrorHandler: PropTypes.func,
    dispatch: PropTypes.func,
};

SetupAxios.defaultProps = {
    axios,
    children: null,
    requestInterceptorHandler: (config) => config,
    requestInterceptorErrorHandler: (error) => Promise.reject(error),
    responseInterceptorSuccessHandler: (response) => response,
    responseInterceptorErrorHandler: (error) => Promise.reject(error),
};

export default connect()(withRouter(withOktaAuth(SetupAxios)));
