/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Accordion } from '../shared';
import AccordionTitle from './grid/accordionTitle';
import './style.scss';
import Grid from './grid';


class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: new Date(), toDate: new Date(), showTable: false,
        };
    }

    componentWillUnmount() {
        this.isComponentMounted = false;
    }

    getDate = (date) => {
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        const yyyy = date.getFullYear();
        if (dd < 10) {
            dd = `0${dd}`;
        }
        if (mm < 10) {
            mm = `0${mm}`;
        }
        return `${yyyy}/${mm}/${dd}`;
    };

    handleChange = (stateName, date) => {
        this.setState({
            [stateName]: date,
        });
    };

    searchHistory = () => {
        const { fromDate, toDate } = this.state;
        const obj = {};
        obj.fromDate = this.getDate(fromDate);
        obj.toDate = this.getDate(toDate);
        this.props.searchPlan(obj);
        this.setState({
            showTable: true,
        });
    }

    render() {
        const { planHistory } = this.props;
        return (
            <div className="history">
                <div className="searchFields">
                    <div className="form-group float-left">
                        <label htmlFor="fromDate">From</label>
                        <DatePicker
                            selected={this.state.fromDate}
                            id="fromDate"
                            className="form-control"
                            onChange={(date) => this.handleChange('fromDate', date)}
                        />
                    </div>
                    <div className="form-group float-left">
                        <label htmlFor="toDate">To</label>
                        <DatePicker
                            selected={this.state.toDate}
                            id="toDate"
                            className="form-control"
                            onChange={(date) => this.handleChange('toDate', date)}
                        />
                    </div>
                    <div className="form-group float-left">
                        <button type="button" className="btn secondary" onClick={this.searchHistory}>Search</button>
                    </div>
                    <div className="clearfix" />
                </div>
                {this.state.showTable && (
                    <div className="history_content">
                        <div className="head no-gutters row">
                            <span className="col-md-4">Marketing Name</span>
                            <span className="col-md-4">Modified Date</span>
                        </div>
                        {planHistory && Object.keys(planHistory).length > 0 ? Object.keys(planHistory).map((data, key) => (
                            <Accordion key={`${planHistory[data][0].modelOffering.marketingName}-${key}`} title={<AccordionTitle data={planHistory[data]} />}>
                                <Grid history={planHistory[data]} />
                            </Accordion>
                        )) : <div className="nodata no-gutters row text-center"><span className="col-12">No records found for search criteria</span></div>}
                    </div>
                )}
            </div>
        );
    }
}


History.propTypes = {
    searchPlan: PropTypes.func,
    planHistory: PropTypes.object,
};


export default History;
