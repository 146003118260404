const utils = {
    getEditorCell: (column, editableColumn) => {
        column.editable = true;
        if (editableColumn.type === 'textarea') {
            column.cellEditor = 'agLargeTextCellEditor';
            column.cellEditorParams = {
                maxLength: '150', // override the editor defaults
                cols: '30',
                rows: '3',
            };
			column.valueSetter = utils.defaultValueSetter;
        } else if (editableColumn.type === 'datepicker') {
            column.cellEditor = 'datePicker';
			column.valueSetter = utils.defaultValueSetter;
        } else if (editableColumn.type === 'singleselect') {
            column.cellEditor = 'agSelectCellEditor';
            column.cellEditorParams = {
                values: editableColumn.options,
            };
			column.valueSetter = utils.defaultValueSetter;
        }
    },
	defaultValueSetter: (params) => {				
		const data = params.data;
		if ((params.oldValue === undefined && params.newValue !== undefined && params.newValue !== '') || (params.oldValue === null &&  params.newValue !== null && params.newValue !== '') || (params.oldValue && params.newValue && params.oldValue.toLowerCase() !== params.newValue.toLowerCase())) {
			data[params.colDef.colId] = params.newValue;
			return true;
		}
		return false;
	}

};
export default utils;
