import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { orderBy } from 'lodash';
import { Dropdown } from '../shared';
import attributeList from '../../constants/filters';
import AgGrid from './agGridPane';
import './style.scss';


class CalenderReport extends Component {
    constructor(props) {
        super(props);
        this.state = { options: [], selectedSeasons: [] };
    }

    componentDidMount() {
        const options = this.getOptions();
        this.setState({ options });
    }

    getOptions = () => {
        let options = [];
        const attribute = attributeList[0];
        if (attribute.lookUp) {
            const { filterFn } = attribute.lookUp;
            options = this.props.seasons;
            options = filterFn ? filterFn(options, attribute.lookUp.valueNode) : options;
            if (attribute.lookUp) {
                const sortNode = attribute.lookUp.sort;
                if (sortNode) {
                    options = orderBy(options, (obj) => parseInt(obj[sortNode], 10), ['desc']);
                }
            }
            const labelNode = attribute.lookUp ? attribute.lookUp.labelNode : 'label';
            const valueNode = attribute.lookUp ? attribute.lookUp.valueNode : 'value';
            options = options.reduce((opts, option) => {
                opts.push({
                    label: option[labelNode],
                    value: option[valueNode],
                });
                return opts;
            }, []);
        } else {
            options = [];
        }
        return options;
    }

    selectCode = (code) => {
        code = code.reduce((opts, option) => {
            opts.push(option.value);
            return opts;
        }, []);
        this.setState({ selectedSeasons: code });
    }

    getReports = () => {
        this.props.getReports(this.state.selectedSeasons);
    }

    render() {
        const { options, selectedSeasons } = this.state;
        const { reports, editableColumns, updateGridPreference, gridPreference } = this.props;
        return (
            <div className="planContainer calendarReport">
                <div className="headerSection">
                    <div className="multiselect">
                        <Dropdown multiSelect options={options} placeholder="Seasons" onChange={(t) => { this.selectCode(t); }} />
                        <button type="button" className="btn primary" disabled={selectedSeasons.length === 0} onClick={this.getReports}>set seasons</button>
                    </div>
                </div>
                <AgGrid
					rowData={reports}
					editableColumns={editableColumns}
					gridPreference={gridPreference}
					updateGridPreference={updateGridPreference}
				/>
            </div>
        );
    }
}


CalenderReport.propTypes = {
    getReports: PropTypes.func,
    reports: PropTypes.array,
    editableColumns: PropTypes.array,
    seasons: PropTypes.array,
};


export default CalenderReport;
