import { call, put } from 'redux-saga/effects';
import historyService from '../services/historyService';
import { setHistory } from '../actions/history';

function* getHistory(data) {
    try {
        const res = yield call(historyService.getHistory, data.payload);
        if (res && res.data) {
            yield put(setHistory(res.data));
        } else {
            yield put(setHistory({}));
        }
    } catch (e) {
        yield put(setHistory([]));
        console.log(e);
    }
}

export default {
    getHistory,
};
