import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import { Dropdown } from '../shared';
import attributeList from '../../constants/filters';
import AgGrid from './agGridPane';
import './style.scss';


class PlanLayout extends Component {
    constructor(props) {
        super(props);
        this.state = { options: [] };
    }

    componentDidMount() {
        const options = this.getOptions();
        this.setState({ options });
    }

    getOptions = () => {
        let options = [];
        const attribute = attributeList[0];
        if (attribute.lookUp) {
            const { filterFn } = attribute.lookUp;
            options = this.props.seasons;
            options = filterFn ? filterFn(options, attribute.lookUp.valueNode) : options;
            if (attribute.lookUp) {
                const sortNode = attribute.lookUp.sort;
                if (sortNode) {
                    options = orderBy(options, (obj) => parseInt(obj[sortNode], 10), ['desc']);
                }
            }
            const labelNode = attribute.lookUp ? attribute.lookUp.labelNode : 'label';
            const valueNode = attribute.lookUp ? attribute.lookUp.valueNode : 'value';
            options = options.reduce((opts, option) => {
                opts.push({
                    label: option[labelNode],
                    value: option[valueNode],
                });
                return opts;
            }, []);
        } else {
            options = [];
        }
        return options;
    }

    selectCode = (code) => {
        this.setState({ selectedSeason: code.value });
        this.props.getPlan(code.value);
    }

    refreshPlanData = () => {
        this.props.getPlan(this.state.selectedSeason);
    }

    isEdited = () => {
        const { editedData } = this.props;
        if (editedData.length > 0) {
            return false;
        }
        return true;
    }

    isUserAdmin = () => {
        const { user } = this.props;
        const adminRole = process.env.REACT_APP_ENV === 'prod' ? 'app.nikebyyou.admin' : 'app.nikebyyou.admin.preprod';
        if (user.groups.findIndex((g) => g.toLowerCase() === adminRole) !== -1) {
            return true;
        }
        return false;
    }

    render() {
        const { options } = this.state;
        const {
            roles,
            planData,
            setEditedData,
            editedData,
            editableColumns,
            user,
            saveEditedData,
			gridPreference,
			updateGridPreference,
        } = this.props;
        return (
            <div className="planContainer">
                <div className="topSection">
                    <button type="button" className="btn secondary float-left" disabled={this.isEdited()} onClick={() => { saveEditedData(); }}>Save</button>
                    <Dropdown
                        options={options}
                        placeholder="Seasons"
                        onChange={(t) => { this.selectCode(t); }}
                    />
                </div>
                <AgGrid
                    rowData={planData}
                    setEditedData={setEditedData}
                    editedData={editedData}
                    editableColumns={editableColumns}
                    user={user}
                    roles={roles}
					gridPreference={gridPreference}
					updateGridPreference={updateGridPreference}
                />
            </div>
        );
    }
}


PlanLayout.propTypes = {
    getPlan: PropTypes.func,
    planData: PropTypes.array,
    editableColumns: PropTypes.array,
    editedData: PropTypes.array,
    setEditedData: PropTypes.func,
    saveEditedData: PropTypes.func,
    user: PropTypes.object,
    seasons: PropTypes.array,
    roles: PropTypes.array,
    setSyncDateToNow: PropTypes.func,
    syncTime: PropTypes.number,
    syncFlexFields: PropTypes.func,
};


export default PlanLayout;
