import { call, put } from 'redux-saga/effects';
import { NotificationManager } from 'react-notifications';
import planService from '../services/planService';
import { setPlans, setUpdatedPlans } from '../actions/planning';

function* getPlans(data) {
    try {
        const res = yield call(planService.getPlan, data.payload);
        if (res && res.data) {
            yield put(setPlans(res.data));
        }
    } catch (e) {
        console.log(e);
    }
}

function* updatePlan(data) {
    try {
        const res = yield call(planService.updatePlan, data.payload);
        if (res && res.data) {
            yield put(setUpdatedPlans(res.data));
            NotificationManager.success(`${res.data.length} row(s) updated successfully`, 'Success', 5000);
        }
    } catch (e) {
        console.log(e);
    }
}

export default {
    getPlans,
    updatePlan,
};
