import http from './httpService';

const getPreference = () => http.get('api/preference');
const updateGridPreference = (payload) => http.put(`api/preference/grid/${payload.preferenceId}`, payload.requestBody);
const insertGridPreference = (payload) => http.post(`api/preference/grid/`, payload.requestBody);

export default {
    getPreference,
	updateGridPreference,
	insertGridPreference,
};
