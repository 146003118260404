export default [
    {
        colId: '',
        headerName: 'Product Code',
        field: '',
        width: 150,
        enableRowGroup: true,
    },
    {
        colId: '',
        headerName: 'Style Name',
        field: '',
        width: 150,
        enableRowGroup: true,
    },
    {
        colId: '',
        headerName: 'Model Name',
        field: '',
        width: 150,
        enableRowGroup: true,
    },
    {
        colId: '',
        headerName: 'DPA',
        field: '',
        width: 100,
        enableRowGroup: true,
    },
    {
        colId: '',
        headerName: 'Category',
        field: '',
        width: 150,
        enableRowGroup: true,
    },
    {
        colId: '',
        headerName: 'Season',
        field: '',
        width: 150,
        enableRowGroup: true,
    },
    {
        colId: '',
        headerName: 'Changed Attribute',
        field: '',
        width: 150,
        enableRowGroup: true,
    },
    {
        colId: '',
        headerName: 'Before',
        field: '',
        width: 100,
        enableRowGroup: true,
    },
    {
        colId: '',
        headerName: 'After',
        field: '',
        width: 100,
        enableRowGroup: true,
    },
    {
        colId: '',
        headerName: 'Modified By',
        field: '',
        width: 150,
        enableRowGroup: true,
    },
    {
        colId: '',
        headerName: 'Time Stamp',
        field: '',
        width: 150,
        enableRowGroup: true,
    },
];
