
import { AgGridReact } from 'ag-grid-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-enterprise';
import GRIDCOLUMNS from '../../../config/calendarReport';
import './style.scss';

class AgGrid extends Component {
    onGridReady = (params) => {
        const { gridPreference } = this.props;
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        const preference = gridPreference && gridPreference.preference ? JSON.parse(window.atob(gridPreference.preference)) : null;
		const columnDefs = preference ? preference : GRIDCOLUMNS.slice();
        this.setColumnDefinition(columnDefs);
    }

    setColumnDefinition(columnDefs) {
        const { editableColumns } = this.props;

		const columns = columnDefs.map((column) => {
			const defaultColumn = GRIDCOLUMNS.find((c) => c.colId === column.colId);
			const index = editableColumns.findIndex((e) => e.name === column.field);

			if (index !== -1) {
				defaultColumn.headerName = editableColumns[index].label;
			}
			column = { ...defaultColumn, ...column };
			return column;
		});

		this.gridApi.setColumnDefs([]);
        this.gridApi.setColumnDefs(columns);
    }

	getCurrentColumnState = () => {
        const columnState = this.columnApi.getColumnState();
        const sortModel = this.columnApi.getColumnState();
        const groupedColumns = this.columnApi.getColumnGroupState();
        const sortedColumnsIds = sortModel.map(sortedColumn => {
            return sortedColumn.colId;
        });
        const updatedColumnDefs = [];
        const defaultColumnDefs = GRIDCOLUMNS.slice();
        columnState.forEach((column) => {
			const defaultColumn = defaultColumnDefs.find((c) => c.colId === column.colId);
			if (defaultColumn) {
				let updatedColumn = { ...defaultColumn, ...column };
				if (sortedColumnsIds && sortedColumnsIds.includes(updatedColumn.colId)) {
					const sortedColumn = _.find(sortModel, (sortedCol) => {return sortedCol.colId === updatedColumn.colId;});
					updatedColumn = { ...updatedColumn, ...sortedColumn };
				} else {
					updatedColumn.sort = null;
				}
				groupedColumns.forEach((groupedColumn) => {
					if (groupedColumn.colId === column.colId) {
						updatedColumn = Object.assign({}, updatedColumn, groupedColumn);
					}
				});
				updatedColumnDefs.push(updatedColumn);
			}
        });
        return updatedColumnDefs;
    }

	/**
     *To save the property to user preference.
     */
    saveGridStateToPreference = () => {
        const updatedColumnDefs = this.getCurrentColumnState();
        this.props.updateGridPreference(updatedColumnDefs);
    }

	restoreToDefault = () => {
		this.props.updateGridPreference(GRIDCOLUMNS);
		this.setColumnDefinition(GRIDCOLUMNS.slice());
	}

    render() {
        return (
            <div
                className="ag-theme-balham calenderReportgrid"
            >
				<span className="bi bi-arrow-repeat" onClick={this.restoreToDefault} title="Restore Grid Default Settings" />
                <AgGridReact
                    rowData={_.cloneDeep(this.props.rowData)}
                    defaultColDef={{ resizable: true, sortable: true, filter: 'agTextColumnFilter' }}
                    groupDisplayType='groupRows'
                    onGridReady={this.onGridReady}
					onSortChanged={this.saveGridStateToPreference}
					onColumnPinned={this.saveGridStateToPreference}
					onColumnRowGroupChanged={this.saveGridStateToPreference}
					onColumnMoved={this.saveGridStateToPreference}
					onColumnResized={(column) => { if(column.finished) { this.saveGridStateToPreference(); } }}
					onColumnVisible={this.saveGridStateToPreference}
					rowGroupPanelShow={'always'}
                />
            </div>
        );
    }
}


AgGrid.propTypes = {
    rowData: PropTypes.array,
    editableColumns: PropTypes.array,
};

export default AgGrid;
