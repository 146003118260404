import http from './httpService';

const getLookUps = () => http.get('/api/general/lookups', {
    params: {
        lookups: [
            'SEASON_YEAR_ID',
            /* 'DIVISION',
            'BUSINESS_ORGANIZATION',
            'CONSUMER_FOCUS',
            'CONSUMER_GROUP',
            'MARKETING_TYPE',
            'CATEGORY_PLM',
            'GEOGRAPHY_NAME',
            'COUNTRY_NAME',
            'CONSUMER_PURPOSE',
            'SAMPLE_DESCRIPTION', */
        ],
    },
});
export default {
    getLookUps,
};
