
import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import history from '../history';
import lookups from './lookup';
import network from './network';
import plan from './plan';
import changeHistory from './history';
import calendarReport from './calendarReport';
import reportData from './productionReport';
import user from './user';
import preference from './preference';

export default combineReducers({
    router: routing(history),
    lookups,
    network,
    plan,
    calendarReport,
    changeHistory,
    user,
    reportData,
	preference,
});
