import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProductionReport from '../components/productionReport';
import * as ProductionReportAction from '../actions/productionReport';
import { dateToNum } from '../util';

class ProductionReportContainer extends React.Component {
    componentDidMount() {
        this.getReportData();
    }

    formatWeekwiseData = () => {
        const { reportData } = this.props;
        if (!reportData) {
            return false;
        }

        const finalData = {};
        const columns = ['flexfield9', 'flexfield11', 'flexfield16'];
		const todayDate = new Date();
		const maxDate = new Date(todayDate.setMonth(todayDate.getMonth() + 3));
        if (reportData && reportData.length > 0) {
            for (let i = 0; i < columns.length; i += 1) {
                reportData.forEach((data) => {
                    const report = { ...data };
                    if (report.newData[columns[i]]) {
						const dateSplits = report.newData[columns[i]].split('/');
						const date = new Date(parseInt(dateSplits[2], 10), parseInt(dateSplits[1], 10) - 1, parseInt(dateSplits[0], 10));
						const todayDate = new Date();
						const firstday = new Date(todayDate.setDate(todayDate.getDate() - todayDate.getDay()));
						if (date <= maxDate && date >= firstday) {
							const weekDates = this.getWeekEnd(report.newData[columns[i]]);
							const startAndEnd = weekDates.split('~');
							if (!finalData[startAndEnd[1]]) {
								finalData[startAndEnd[1]] = [];
							}
							report.action = columns[i];
							// eslint-disable-next-line prefer-destructuring
							report.weekStartDate = startAndEnd[0];
							if (!finalData[startAndEnd[1]].find((r) => r.product.productCode === report.product.productCode && r.action === columns[i] && r.modelOffering.categoryProductLineManagerUserAccountCode === report.modelOffering.categoryProductLineManagerUserAccountCode)) {
								finalData[startAndEnd[1]].push(report);
							}
						}
                    }
                });
            }
        }

		// sort the finalData
		const sortedData = Object.keys(finalData).sort(function(a, b){
			return dateToNum(a) - dateToNum(b);
		}).reduce((obj, key) => {
			obj[key] = finalData[key].sort((c, d) => {
				return dateToNum(c.newData[c.action]) - dateToNum(d.newData[d.action]);
			});
			return obj;
		}, {});
		  

        return sortedData;
    }

    getWeekEnd = (date) => {
        const dateSplits = date.split('/');
        const curr = new Date(parseInt(dateSplits[2], 10), parseInt(dateSplits[1], 10) - 1, parseInt(dateSplits[0], 10));

        const firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
		const lastday = new Date(curr.setDate(curr.getDate() - curr.getDay()+6));

        return `${firstday.getDate()}/${firstday.getMonth() + 1}/${firstday.getFullYear()}~${lastday.getDate()}/${lastday.getMonth() + 1}/${lastday.getFullYear()}`;
    }

    getReportData = () => {
        const obj = {
            dropDate: 'flexfield11',
            launchDate: 'flexfield9',
            updateDate: 'flexfield16',
        };
        this.props.actions.getReportData(obj);
    }

    render() {
        const finalData = this.formatWeekwiseData();
        return (
            <ProductionReport finalData={finalData} />
        );
    }
}


function mapStateToProps(state) {
    return {
        reportData: state.reportData,
    };
}


function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(ProductionReportAction, dispatch),
    };
}

ProductionReportContainer.propTypes = {
    actions: PropTypes.object,
    reportData: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductionReportContainer);
