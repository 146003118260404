import http from './httpService';

const getHistory = (obj) => http.get('api/planning/history', {
    params: {
        data: obj,
    },
});

export default {
    getHistory,
};
