import { call, put } from 'redux-saga/effects';
import preferenceService from '../services/preferenceService';
import { setUserPreference, setUpdatedGridPreference } from '../actions/preference';

function* getPreference() {
    try {
        const res = yield call(preferenceService.getPreference);
        if (res && res.data) {
            yield put(setUserPreference(res.data));
        }
    } catch (e) {
        console.log(e);
    }
}

function* updateGridPreference(data) {
    try {
        const res = yield call(preferenceService.updateGridPreference, data.payload);
        if (res && res.data) {
            yield put(setUpdatedGridPreference(res.data));
        }
    } catch (e) {
        console.log(e);
    }
}

function* insertGridPreference(data) {
    try {
        const res = yield call(preferenceService.insertGridPreference, data.payload);
        if (res && res.data) {
            yield put(setUpdatedGridPreference(res.data));
        }
    } catch (e) {
        console.log(e);
    }
}

export default {
    getPreference,
    updateGridPreference,
	insertGridPreference,
};
