import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from 'react-bootstrap/Table';
import GRIDCOLUMNS from '../../../config/historyConfig';

class Grid extends Component {
    getNewData = () => {
        let productCode = this.props.history;
        productCode = productCode.map((p) => {
            const { newData, oldData } = p;
            const newDataKeys = Object.keys(newData);
            newDataKeys.map((n) => {
                if (oldData && oldData[n] && oldData[n] === newData[n]) {
                    delete newData[n];
                }
                return n;
            });
            return p;
        });
        return productCode;
    }

    render() {
        return (
            <Table responsive hover>
                <thead>
					<tr>
						{GRIDCOLUMNS.map((column) => (
							<th key={column.headerName}>{column.headerName}</th>
						))}
					</tr>
                </thead>
                <tbody className="text-center">
                    { this.getNewData().map((obj) => (
                        Object.keys(obj.newData).map((changedAttr, key) => (
                            <tr key={`${obj.product.productId}-${key}`}>
                                <td>{obj.product.productCode}</td>
                                <td>{obj.style && obj.style.styleName ? obj.style.styleName : ''}</td>
                                <td>{obj.model.modelName}</td>
								<td>{obj.developmentProjectAliasDescription}</td>
                                <td>{obj.product && obj.product.categoryDescription ? obj.product.categoryDescription : ''}</td>
                                <td>{obj.seasonYearAbbreviation}</td>
                                <td>{changedAttr}</td>
                                <td>{obj.oldData && obj.oldData[changedAttr] ? obj.oldData[changedAttr] : ''}</td>
                                <td>{obj.newData[changedAttr]}</td>
                                <td>{obj.updatedBy}</td>
                                <td>{new Date(obj.updatedDate).toDateString().slice(4)}</td>
                            </tr>
                        ))
                    ))}
                </tbody>
            </Table>
        );
    }
}


Grid.propTypes = {
    history: PropTypes.array,
};

export default Grid;
