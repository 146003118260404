export default [{
    name: 'seasons',
    displayValue: 'Season',
    type: 'multiSelect',
    maxSelection: 4,
    mandatory: true,
    default: true, // decide to show the filters when app loaded
    skipMandatory: false,
    isOpen: true,
    lookUp: {
        name: 'SEASON_YEAR_ID',
        labelNode: 'CODE',
        valueNode: 'CODE',
        sort: 'VALUE',
    },
    validation: (filterValue) => (filterValue && filterValue.length <= 4),
    id: 1,
}];
