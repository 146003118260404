export default [
    {
        colId: 'divisionDescription',
        headerName: 'Division',
        field: 'divisionDescription',
        width: 100,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model.divisionDescription) {
                renderText = params.data.model.divisionDescription.toString();
            }
            return renderText;
        },
    }, {
        colId: 'modelGroup',
        headerName: 'Model Group',
        field: '',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model[params.colDef.colId] && params.data.model[params.colDef.colId][0].modelGroupDescription) {
                renderText = params.data.model[params.colDef.colId][0].modelGroupDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'modelOfferingIdentifier',
        headerName: 'MO ID',
        field: 'modelOfferingIdentifier',
        width: 100,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.modelOffering && params.data.modelOffering.modelOfferingIdentifier) {
                renderText = params.data.modelOffering.modelOfferingIdentifier;
            }
            return renderText;
        },
    },
    {
        colId: 'poID',
        headerName: 'PO ID',
        field: 'poID',
        width: 100,
        enableRowGroup: true,
    },
    {
        colId: 'productId',
        headerName: 'Product ID',
        field: 'productId',
        width: 100,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product[params.colDef.colId]) {
                renderText = params.data.product[params.colDef.colId];
            }
            return renderText;
        },
    },
    {
        colId: 'initialSeasonYearAbbreviation',
        headerName: 'Style Init Season',
        field: 'initialSeasonYearAbbreviation',
        width: 200,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.style && params.data.style.initialSeasonYearAbbreviation) {
                renderText = params.data.style.initialSeasonYearAbbreviation;
            }
            return renderText;
        },
    },
    {
        colId: 'productInitialSeasonYearAbbreviation',
        headerName: 'Product Init Season',
        field: 'productInitialSeasonYearAbbreviation',
        width: 200,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product.initialSeasonYearAbbreviation) {
                renderText = params.data.product.initialSeasonYearAbbreviation;
            }
            return renderText;
        },
    },
    {
        colId: 'productCarryOverStatusIndicator',
        headerName: 'Product C/O Id',
        field: 'productCarryOverStatusIndicator',
        width: 150,
        enableRowGroup: true,
    },
    {
        colId: 'styleCarryOverStatusIndicator',
        headerName: 'Style C/O Id',
        field: 'styleCarryOverStatusIndicator',
        width: 150,
        enableRowGroup: true,
    },
    {
        colId: 'lineEvolutionDescription',
        headerName: 'Line Evloution',
        field: 'lineEvolutionDescription',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.modelOffering && params.data.modelOffering.lineEvolutionDescription) {
                renderText = params.data.modelOffering.lineEvolutionDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'productBusinessOrganizationDescription',
        headerName: 'Model Originator Bus Organization',
        field: '',
        width: 200,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product.originator && params.data.product.originator.businessOrganizationDescription) {
                renderText = params.data.product.originator.businessOrganizationDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'businessOrganizationDescription',
        headerName: 'Style Sponsor Bus Organization',
        field: 'businessOrganizationDescription',
        width: 200,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model_offering && params.data.model_offering.businessOrganizationDescription) {
                renderText = params.data.model_offering.businessOrganizationDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'styleName',
        headerName: 'Style Name',
        field: 'styleName',
        width: 100,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.style && params.data.style.styleName) {
                renderText = params.data.style.styleName;
            }
            return renderText;
        },
    },
    {
        colId: 'categoryProductLineManagerUserAccountName',
        headerName: 'MO Category PLM',
        field: 'categoryProductLineManagerUserAccountName',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.modelOffering && params.data.modelOffering.categoryProductLineManagerUserAccountName) {
                renderText = params.data.modelOffering.categoryProductLineManagerUserAccountName;
            }
            return renderText;
        },
    },
    {
        colId: 'categoryProductLineManagerName',
        headerName: 'PO Initiating PLM',
        field: 'categoryProductLineManagerName',
        width: 150,
        enableRowGroup: true,
    },
    {
        colId: 'categoryDescription',
        headerName: 'Product Category',
        field: 'categoryDescription',
        width: 130,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product.categoryDescription) {
                renderText = params.data.product.categoryDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'globalCategoryCoreFocusDescription',
        headerName: 'Product GCCF',
        field: 'globalCategoryCoreFocusDescription',
        width: 130,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product.globalCategoryCoreFocusDescription) {
                renderText = params.data.product.globalCategoryCoreFocusDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'globalCategorySummaryDescription',
        headerName: 'Product GCS',
        field: 'globalCategorySummaryDescription',
        width: 120,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product.globalCategorySummaryDescription) {
                renderText = params.data.product.globalCategorySummaryDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'hierCategoryDescription',
        headerName: 'Global Reporting Category',
        field: '',
        width: 200,
        enableRowGroup: true,
		valueGetter: (params) => {
            let renderText = '';
			let globalCategory = '';
			let globalCategoryFocus = '';
            if (params.data && params.data.product && params.data.product.globalCategorySummaryDescription) {
                globalCategory = params.data.product.globalCategorySummaryDescription.toString();
            }
            if (params.data && params.data.product && params.data.product.globalCategoryCoreFocusDescription) {
                globalCategoryFocus = params.data.product.globalCategoryCoreFocusDescription.toString();
            }
			switch(globalCategory) {
				case 'RUNNING':
					renderText = 'RUNNING';
					break;
				case 'NIKE SPORTSWEAR':
					renderText = 'SPORTSWEAR';
					break;
				case 'FOOTBALL, BASEBALL, AT':
					if (globalCategoryFocus) {
						if (globalCategoryFocus.includes('AMERICAN FOOTBALL')) {
							renderText = 'AMERICAN FOOTBALL';
						} else if (globalCategoryFocus.includes('MEN TRAINING')) {
							renderText = 'ATHLETIC TRAINING';
						} else if (globalCategoryFocus.includes('BASEBALL')) {
							renderText = 'BASEBALL';
						} else {
							renderText = 'UNKNOWN SPORT';
						}
					} else {
						renderText = 'UNKNOWN SPORT';
					}
					break;
				case 'WOMEN TRAINING':
					renderText = 'WOMEN TRAINING';
					break;
				case 'BASKETBALL':
					if (globalCategoryFocus) {
						if (globalCategoryFocus.includes('NIKE BASKETBALL')) {
							renderText = 'BASKETBALL';
						} else if (globalCategoryFocus.includes('JORDAN BRAND')) {
							renderText = 'JORDAN';
						} else {
							renderText = 'OTHER BASKETBALL';
						}
					} else {
						renderText = 'OTHER BASKETBALL';
					}
					break;
				case 'ACTION OUTDOOR':
					renderText = 'NIKESB';
					break;
				case 'FOOTBALL/SOCCER':
					renderText = 'GLOBAL FOOTBALL';
					break;
				case 'GOLF':
					renderText = 'GOLF';
					break;
				case 'YOUNG ATHLETES':
					renderText = 'YOUNG ATHLETE';
					break;
				case 'OTHER':
					if (globalCategoryFocus) {
						if (globalCategoryFocus.includes('TENNIS')) {
							renderText = 'TENNIS';
						} else {
							renderText = globalCategoryFocus;
						}
					} else {
						renderText = 'OTHER';
					}
					break;
				default:
					if (!globalCategory || !globalCategoryFocus || globalCategory === '' || globalCategoryFocus === '') {
						renderText = 'NULL';
					} else {
						renderText = 'OTHER';
					}
			}
            return renderText;
        },
    },
    {
        colId: 'silhouetteTypeDescription',
        headerName: 'Silhouette Type',
        field: 'silhouetteTypeDescription',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model.silhouetteTypeDescription) {
                renderText = params.data.model.silhouetteTypeDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'silhouetteDescription',
        headerName: 'Silhouette',
        field: 'silhouetteDescription',
        width: 100,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model.silhouetteDescription) {
                renderText = params.data.model.silhouetteDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'consumerGroupDescription',
        headerName: 'Consumer Group',
        field: 'consumerGroupDescription',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.modelOffering && params.data.modelOffering.consumerGroupDescription) {
                renderText = params.data.modelOffering.consumerGroupDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'consumerPurposeDescription',
        headerName: 'Model Originator Consumer Purpose',
        field: 'consumerPurposeDescription',
        width: 200,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model.consumerPurposeDescription) {
                renderText = params.data.model.consumerPurposeDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'retailSizeRange', headerName: 'Size Range', field: '', width: 200, enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product[params.colDef.colId]) {
                renderText = params.data.product[params.colDef.colId];
            }
            return renderText;
        },
    },
    {
        colId: 'productDimensionDescription',
        headerName: 'Dimension',
        field: '',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product.productDimensionDescription) {
                renderText = params.data.product.productDimensionDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'developmentProjectAliasDescription',
        headerName: 'DPA',
        field: 'developmentProjectAliasDescription',
        width: 150,
        enableRowGroup: true,
    },
    {
        colId: 'materialIntentDescription',
        headerName: 'Material Intent',
        field: '',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product.materialIntentDescription) {
                renderText = params.data.product.materialIntentDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'Platform1',
        headerName: 'Platform 1',
        field: 'Platform1',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model.placedPlatform && params.data.model.placedPlatform.length > 0) {
                const platform = params.data.model.placedPlatform.find(p => p.platformRankNumber === "1");
				renderText = (platform) ? platform.platformDescription : '';
            }
            return renderText;
        },
    },
    {
        colId: 'Platform2',
        headerName: 'Platform 2',
        field: 'Platform2',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model.placedPlatform && params.data.model.placedPlatform.length > 0) {
                const platform = params.data.model.placedPlatform.find(p => p.platformRankNumber === "2");
				renderText = (platform) ? platform.platformDescription : '';
            }
            return renderText;
        },
    },
    {
        colId: 'Platform3',
        headerName: 'Platform 3',
        field: 'Platform3',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model.placedPlatform && params.data.model.placedPlatform.length > 0) {
                const platform = params.data.model.placedPlatform.find(p => p.platformRankNumber === "3");
				renderText = (platform) ? platform.platformDescription : '';
            }
            return renderText;
        },
    },
    {
        colId: 'Platform4',
        headerName: 'Platform 4',
        field: 'Platform4',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model.placedPlatform && params.data.model.placedPlatform.length > 0) {
                const platform = params.data.model.placedPlatform.find(p => p.platformRankNumber === "4");
				renderText = (platform) ? platform.platformDescription : '';
            }
            return renderText;
        },
    },
    {
        colId: 'WHQ_DEVELOPER',
        headerName: 'WHQ Developer',
        field: 'WHQ_DEVELOPER',
        width: 150,
        enableRowGroup: true,
    },
    {
        colId: '',
        headerName: 'MMX Notes',
        field: '',
        width: 150,
        enableRowGroup: true,
    },
    {
        colId: 'franchise',
        headerName: 'Franchise',
        field: 'franchise',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model.franchise) {
                renderText = params.data.model.franchise;
            }
            return renderText;
        },
    },
    {
        colId: 'familyDescription',
        headerName: 'Family',
        field: 'familyDescription',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.modelOffering && params.data.modelOffering.familyDescription) {
                renderText = params.data.modelOffering.familyDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'FACTORY',
        headerName: 'Factory',
        field: 'FACTORY',
        width: 100,
		enableRowGroup: true
    },
    {
        colId: 'WHQ_DEVELOPER2',
        headerName: 'WHQ Developer 2',
        field: 'WHQ_DEVELOPER',
        width: 150,
        enableRowGroup: true,
    },
    {
        colId: 'wholesalePrice',
        headerName: 'Target WS Price',
        field: 'wholesalePrice',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.modelOffering && params.data.modelOffering.wholesalePrice) {
                renderText = params.data.modelOffering.wholesalePrice;
            }
            return renderText;
        },
    },
    {
        colId: 'retailPrice',
        headerName: 'Target Retail Price',
        field: 'retailPrice',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.modelOffering && params.data.modelOffering.retailPrice) {
                renderText = params.data.modelOffering.retailPrice;
            }
            return renderText;
        },
    },
    {
        colId: 'styleNumber',
        headerName: 'Style Number',
        field: 'styleNumber',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.style && params.data.style.styleNumber) {
                renderText = params.data.style.styleNumber;
            }
            return renderText;
        },
    },
    {
        colId: 'productCode',
        headerName: 'Product Code',
        field: 'productCode',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product.productCode) {
                renderText = params.data.product.productCode;
            }
            return renderText;
        },
    },
    {
        colId: 'marketingName',
        headerName: 'Marketing Name',
        field: 'marketingName',
        width: 200,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.modelOffering && params.data.modelOffering.marketingName) {
                renderText = params.data.modelOffering.marketingName;
            }
            return renderText;
        },
    },
    {
        colId: 'modelName',
        headerName: 'Model Name',
        field: 'modelName',
        width: 200,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model.modelName) {
                renderText = params.data.model.modelName;
            }
            return renderText;
        },
    },
    {
        colId: 'genderDescription',
        headerName: 'Gender',
        field: 'genderDescription',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model.genderDescription) {
                renderText = params.data.model.genderDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'ageDescription',
        headerName: 'Age',
        field: 'ageDescription',
        width: 100,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model.ageDescription) {
                renderText = params.data.model.ageDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'colorwayCode',
        headerName: 'colorway code',
        field: 'colorwayCode',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product.colorwayCode) {
                renderText = params.data.product.colorwayCode;
            }
            return renderText;
        },
    },
    {
        colId: 'flexfield1',
        headerName: '',
        field: 'flexfield1',
        width: 150,
        enableRowGroup: true,
    },
    {
        colId: 'flexfield2',
        headerName: '',
        field: 'flexfield2',
        width: 150,
        enableRowGroup: true,
    },
    {
        colId: '',
        headerName: 'Nike By You PlatForm2',
        field: '',
        width: 200,
        enableRowGroup: true,
    },
    {
        colId: '',
        headerName: 'Nike By You PlatForm3',
        field: '',
        width: 200,
        enableRowGroup: true,
    },
    {
        colId: '',
        headerName: 'Nike By You PlatForm4',
        field: '',
        width: 200,
        enableRowGroup: true,
    },
    {
        colId: 'flexfield3', headerName: '', field: 'flexfield3', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield7', headerName: '', field: 'flexfield7', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield9', headerName: '', field: 'flexfield9', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield10', headerName: '', field: 'flexfield10', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield11', headerName: '', field: 'flexfield11', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield12', headerName: '', field: 'flexfield12', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield16', headerName: '', field: 'flexfield16', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield17', headerName: '', field: 'flexfield17', width: 150, enableRowGroup: true,
    },
    {
        colId: 'offeringStatusIndicator',
        headerName: 'PO Status',
        field: 'offeringStatusIndicator',
        width: 130,
        enableRowGroup: true,
    },
    {
        colId: 'modelShortName',
        headerName: 'Model Short Name',
        field: 'modelShortName',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model.modelShortName) {
                renderText = params.data.model.modelShortName;
            }
            return renderText;
        },
    },
    {
        colId: 'seasonYearAbbreviation', headerName: 'Season', field: 'seasonYearAbbreviation', width: 100, enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.modelOffering && params.data.modelOffering[params.colDef.colId]) {
                renderText = params.data.modelOffering[params.colDef.colId];
            }
            return renderText;
        },
    },
    {
        colId: 'flexfield20', headerName: '', field: 'flexfield20', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield21', headerName: '', field: 'flexfield21', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield22', headerName: '', field: 'flexfield22', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield4', headerName: '', field: 'flexfield4', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield5', headerName: '', field: 'flexfield5', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield6', headerName: '', field: 'flexfield6', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield23', headerName: '', field: 'flexfield23', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield8', headerName: '', field: 'flexfield8', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield24', headerName: '', field: 'flexfield24', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield25', headerName: '', field: 'flexfield25', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield26', headerName: '', field: 'flexfield26', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield27', headerName: '', field: 'flexfield27', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield13', headerName: '', field: 'flexfield13', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield14', headerName: '', field: 'flexfield14', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield15', headerName: '', field: 'flexfield15', width: 150, enableRowGroup: true,
    },
    {
        colId: 'modelGroupDescription',
        headerName: 'Model Group Name',
        field: 'modelGroupDescription',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model.modelGroup && params.data.model.modelGroup[0].modelGroupDescription) {
                renderText = params.data.model.modelGroup[0][params.colDef.colId];
            }
            return renderText;
        },
    },
    {
        colId: 'leagueTeamAthlete',
        headerName: 'League/Team/athelete',
        field: '',
        width: 200,
        enableRowGroup: true,
        valueGetter: (params) => {
			let renderText = [];
			
            if (params.data && params.data.product && params.data.product.leagueDescription) {
                renderText.push(params.data.product.leagueDescription);
            }
            if (params.data && params.data.product && params.data.product.teamName) {
                renderText.push(params.data.product.teamName);
            }
            if (params.data && params.data.product && params.data.product.athleteName) {
                renderText.push(params.data.product.athleteName);
            }
            return renderText.join('/');
        },
    },
    {
        colId: 'flexfield28', headerName: '', field: 'flexfield28', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield29', headerName: '', field: 'flexfield29', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield30', headerName: '', field: 'flexfield30', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield31', headerName: '', field: 'flexfield31', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield32', headerName: '', field: 'flexfield32', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield33', headerName: '', field: 'flexfield33', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield34', headerName: '', field: 'flexfield34', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield35', headerName: '', field: 'flexfield35', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield36', headerName: '', field: 'flexfield36', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield37', headerName: '', field: 'flexfield37', width: 150, enableRowGroup: true,
    },
    {
        colId: 'flexfield38', headerName: '', field: 'flexfield38', width: 150, enableRowGroup: true,
    },
	{
		colId: 'silo', headerName: 'Silo', field: 'silo', width: 180, enableRowGroup: true,
		valueGetter: (params) => {
			let renderText = '';
			
            if (params.data && params.data.product && params.data.product.siloDescription) {
                renderText = params.data.product.siloDescription;
            }
            return renderText;
        },
	},
	{
		colId: 'moGroupCollection', headerName: 'MO Group Collection', field: 'moGroupCollection', width: 180, enableRowGroup: true,
		valueGetter: (params) => {
			let renderText = [];
			
            if (params.data && params.data.modelOffering && params.data.modelOffering.moGroup && params.data.modelOffering.moGroup.length > 0) {
                renderText = params.data.modelOffering.moGroup.map((mg) => mg.modelOfferingGroupDescription).join(', ');
            }
            return renderText;
        },
	},
];
