import DatePicker from 'react-datepicker';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

/**
 * This component is a custom editor for editing of date in main grid.
 *@example
 *cellEditorFramework: DatePickerEditor
 *
 */
export default class CustomDateComponent extends React.Component {
    /**
     * grid data is passed to the custom editor as props which includes column
     * configuration, rownode and data
     *
     * @param {string} value - has the grid element value
     * @param {Object} api: grid api
     * @param {Object} node: Row node
     * @param {Number} keyPress: keycode
     */
    constructor(props) {
        super(props);
        this.input = React.createRef();
        /**
         * initializes cancelBeforeStart with a boolean value
         */
        this.cancelBeforeStart = this.props.charPress && ('1234567890'.indexOf(this.props.charPress) < 0);
        const value = (this.props.value) ? new Date(this.props.value) : null;
		// const value = null;
        /**
         * @param {string} value - has the grid element value
         */
        this.state = {
            value
        };
    }
    /**
     * invokes the focus() after mounting
     *
     */
    componentDidMount() {
        this.focus();
    }
    /**
     * invokes the focusAndSelect() after a timeout
     *
     */
    focus() {
        setTimeout(this.focusAndSelect, 0);
    }
    /**
     * This method returns the char code from event
     */
    getCharCodeFromEvent(event) {
        const e = event || window.event;
        return (typeof e.which === 'undefined') ? e.keyCode : e.which;
    }

    /**
     * Implements the delete key press by clearing the value
     *
     */
    focusAndSelect = ()=>{
        if (this.input.current && this.props.column) {
            this.input.current.setFocus();
            if(this.props.keyPress === 46 || this.props.keyPress === 8) {
                this.setState({value: ''});
            }
        }
    }

    /**
     * Returns true, so that the editor is shown as popup
     */
    isPopup() {
        return true;
    }
    /**
     * Returns the final value to the grid, the result of the editing
     * @return {String} value
     *
     */
    getValue() {
        return this.state.value ? moment(this.state.value).format('MM/DD/YYYY') : '';
    }
    /**
     * Sets the edited value to state and invokes grid api, stopEditing
     */
    handleChange = (date) => {
        this.setState({value: date}, () => {			
			this.props.api.stopEditing();
			this.props.api.setFocusedCell(this.props.node.rowIndex, this.props.column.colId);
		});
    }
    /**
     * render
     * @return {ReactElement} markup
     */
    render() {
        return (
            <div style={{fontSize: '11px'}}>
                <DatePicker
                    ref={this.input}
                    placeholderText="MM/DD/YYYY"
                    showYearDropdown
					showMonthDropdown
                    selected={this.state.value}
                    onChange={this.handleChange}
                    onChangeRaw={this.validateDate}
                    onFocus={(e) => e.currentTarget.select()}
                />
            </div>
        );
    }
}

CustomDateComponent.propTypes = {
    charPress: PropTypes.string,
    value: PropTypes.string,
    column: PropTypes.object,
    node: PropTypes.object,
    api: PropTypes.object,
    keyPress: PropTypes.number,
    onChange: PropTypes.func,
    type: PropTypes.string
};