import React from 'react';
import NewRelic from 'new-relic-agent-react';
import { Provider } from 'react-redux';

import './assets/styles/main.scss';

import store from './store';
import saga from './sagas';
import { sagaMiddleware } from './middleware';
import Router from './router';

sagaMiddleware.run(saga);

function App() {
    return (
        <Provider store={store}>
            <div className="App">
                {process.env.NEWRELIC_KEY
                    ? (
                        <head>
                            <NewRelic licenseKey={process.env.NEWRELIC_KEY} applicationID={process.env.NEWRELIC_KEY} />
                        </head>
                    )
                    : null}
                <Router />
            </div>
        </Provider>
    );
}

export default App;
