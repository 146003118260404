import http from './httpService';

const getReportData = (obj) => http.get('api/planning/production', {
    params: {
        data: obj,
    },
});

export default {
    getReportData,
};
