import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import history from './history';

const historyMiddleware = routerMiddleware(history);

const composeEnhancers = composeWithDevTools({
    name: 'Nike By You',
});

export const sagaMiddleware = createSagaMiddleware();

const middlewares = [
    historyMiddleware,
    sagaMiddleware,
];

export default [
    composeEnhancers(applyMiddleware(...middlewares)),
];
