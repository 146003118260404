export default [
    {
        colId: 'divisionDescription',
        headerName: 'Division',
        field: 'divisionDescription',
        width: 100,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model.divisionDescription) {
                renderText = params.data.model.divisionDescription.toString();
            }
            return renderText;
        },
    },
    {
        colId: 'retailPrice',
        headerName: 'Retail Price',
        field: 'retailPrice',
        width: 130,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.modelOffering) {
                renderText = params.data.modelOffering[params.colDef.colId].toString();
            }
            return renderText;
        },
    },
    {
        colId: 'genderDescription',
        headerName: 'Gender',
        field: 'genderDescription',
        width: 100,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model) {
                renderText = params.data.model[params.colDef.colId].toString();
            }
            return renderText;
        },
    },
    {
        colId: 'businessOrganizationDescription', headerName: 'Global Report Category', field: '', width: 150, enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
			let globalCategory = '';
			let globalCategoryFocus = '';
            if (params.data && params.data.product && params.data.product.globalCategorySummaryDescription) {
                globalCategory = params.data.product.globalCategorySummaryDescription.toString();
            }
            if (params.data && params.data.product && params.data.product.globalCategoryCoreFocusDescription) {
                globalCategoryFocus = params.data.product.globalCategoryCoreFocusDescription.toString();
            }
			switch(globalCategory) {
				case 'RUNNING':
					renderText = 'RUNNING';
					break;
				case 'NIKE SPORTSWEAR':
					renderText = 'SPORTSWEAR';
					break;
				case 'FOOTBALL, BASEBALL, AT':
					if (globalCategoryFocus) {
						if (globalCategoryFocus.includes('AMERICAN FOOTBALL')) {
							renderText = 'AMERICAN FOOTBALL';
						} else if (globalCategoryFocus.includes('MEN TRAINING')) {
							renderText = 'ATHLETIC TRAINING';
						} else if (globalCategoryFocus.includes('BASEBALL')) {
							renderText = 'BASEBALL';
						} else {
							renderText = 'UNKNOWN SPORT';
						}
					} else {
						renderText = 'UNKNOWN SPORT';
					}
					break;
				case 'WOMEN TRAINING':
					renderText = 'WOMEN TRAINING';
					break;
				case 'BASKETBALL':
					if (globalCategoryFocus) {
						if (globalCategoryFocus.includes('NIKE BASKETBALL')) {
							renderText = 'BASKETBALL';
						} else if (globalCategoryFocus.includes('JORDAN BRAND')) {
							renderText = 'JORDAN';
						} else {
							renderText = 'OTHER BASKETBALL';
						}
					} else {
						renderText = 'OTHER BASKETBALL';
					}
					break;
				case 'ACTION OUTDOOR':
					renderText = 'NIKESB';
					break;
				case 'FOOTBALL/SOCCER':
					renderText = 'GLOBAL FOOTBALL';
					break;
				case 'GOLF':
					renderText = 'GOLF';
					break;
				case 'YOUNG ATHLETES':
					renderText = 'YOUNG ATHLETE';
					break;
				case 'OTHER':
					if (globalCategoryFocus) {
						if (globalCategoryFocus.includes('TENNIS')) {
							renderText = 'TENNIS';
						} else {
							renderText = globalCategoryFocus;
						}
					} else {
						renderText = 'OTHER';
					}
					break;
				default:
					if (!globalCategory || !globalCategoryFocus || globalCategory === '' || globalCategoryFocus === '') {
						renderText = 'NULL';
					} else {
						renderText = 'OTHER';
					}
			}
            return renderText;
        },
    },
    {
        colId: 'marketingName',
        headerName: 'Marketing Name',
        field: 'marketingName',
        width: 200,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.modelOffering && params.data.modelOffering.marketingName) {
                renderText = params.data.modelOffering.marketingName.toString();
            }
            return renderText;
        },
    },
    {
        colId: 'flexfield1',
        headerName: '',
        field: 'flexfield1',
        editable: false,
        width: 200,
        enableRowGroup: true,
        cellEditorPopup: true,
    },
    {
        colId: 'modelName',
        headerName: 'Model Name',
        field: 'modelName',
        width: 200,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model[params.colDef.colId]) {
                renderText = params.data.model[params.colDef.colId];
            }
            return renderText;
        },
    },
    {
        colId: 'productCarryOverStatusIndicator', headerName: 'Product CarryOver Status', field: 'productCarryOverStatusIndicator', width: 200, enableRowGroup: true,
    },
    {
        colId: 'retailSizeRange', headerName: 'Size Range', field: '', width: 100, enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product[params.colDef.colId]) {
                renderText = params.data.product[params.colDef.colId];
            }
            return renderText;
        },
    },
    {
        colId: 'developmentProjectAliasDescription', headerName: 'DPA', field: 'developmentProjectAliasDescription', width: 100, enableRowGroup: true,
    },
    {
        colId: 'placedPlatform', headerName: 'Platform1', field: '', width: 250, enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model[params.colDef.colId] && params.data.model[params.colDef.colId].length > 0) {
                renderText = params.data.model[params.colDef.colId].map((pp) => {
					var text = '';
					if (pp.platformDescription) {
						text = pp.platformDescription;
					}
					if (pp.placementDescription) {
						text += `(${pp.placementDescription})`;
					}
					return text;
				}).join(', ');
            }
            return renderText;
        },
    },
    {
        colId: 'flexfield2', headerName: '', field: 'flexfield2', editable: false, width: 150, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'materialIntentDescription', headerName: 'Material Intent', field: '', width: 130, enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product.materialIntentDescription) {
                renderText = params.data.product.materialIntentDescription;
            }
            return renderText;
        },
    },
    {
        colId: 'flexfield3', headerName: '', field: 'flexfield3', editable: false, width: 200, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield4', headerName: '', field: 'flexfield4', editable: false, width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield5', headerName: '', field: 'flexfield5', editable: false, width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield6', headerName: '', field: 'flexfield6', editable: false, width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield7', headerName: '', field: 'flexfield7', editable: false, width: 200, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield8', headerName: '', field: 'flexfield8', editable: false, width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield9', headerName: '', field: 'flexfield9', editable: false, width: 130, enableRowGroup: true, cellEditorPopup: true
    },
    {
        colId: 'productCode',
        headerName: 'Product Code',
        field: 'productCode',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product.productCode) {
                renderText = params.data.product.productCode;
            }
            return renderText;
        },
    },
    {
        colId: 'styleNumber',
        headerName: 'Style Number',
        field: 'styleNumber',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.style && params.data.style.styleNumber) {
                renderText = params.data.style.styleNumber;
            }
            return renderText;
        },
    },
    {
        colId: 'primaryLabColorCode',
        headerName: 'Color Code',
        field: 'primaryLabColorCode',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product[params.colDef.colId]) {
                renderText = params.data.product[params.colDef.colId];
            }
            return renderText;
        },
    },
    {
        colId: 'productDimensionDescription', headerName: 'Width', field: '', width: 100, enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product[params.colDef.colId]) {
                renderText = params.data.product[params.colDef.colId];
            }
            return renderText;
        },
    },
    {
        colId: 'silhouetteDescription',
        headerName: 'Silhouette',
        field: 'silhouetteDescription',
        width: 100,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model) {
                renderText = params.data.model[params.colDef.colId];
            }
            return renderText;
        },
    },
    {
        colId: 'flexfield10', headerName: '', field: 'flexfield10', editable: false, width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'poID', headerName: 'PO ID', field: 'poID', width: 100, enableRowGroup: true,
    },
    {
        colId: 'offeringStatusIndicator', headerName: 'PO Status', field: 'offeringStatusIndicator', width: 100, enableRowGroup: true,
    },
    {
		colId: 'seasonYearAbbreviation', headerName: 'Season', field: 'seasonYearAbbreviation', width: 100, enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.modelOffering && params.data.modelOffering[params.colDef.colId]) {
                renderText = params.data.modelOffering[params.colDef.colId];
            }
            return renderText;
        },
    },
    {
        colId: 'flexfield11', headerName: '', field: 'flexfield11', editable: false, width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield12', headerName: '', editable: false, field: 'flexfield12', width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield13', headerName: '', editable: false, field: 'flexfield13', width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield14', headerName: '', editable: false, field: 'flexfield14', width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield15', headerName: '', editable: false, field: 'flexfield15', width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield16', headerName: '', editable: false, field: 'flexfield16', width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield17', headerName: '', editable: false, field: 'flexfield17', width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield18', headerName: '', editable: false, field: 'flexfield18', width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield19', headerName: '', editable: false, field: 'flexfield19', width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield20', headerName: '', editable: false, field: 'flexfield20', width: 130, enableRowGroup: true,
    },
    {
        colId: 'flexfield21', headerName: '', editable: false, field: 'flexfield21', width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield22', headerName: '', editable: false, field: 'flexfield22', width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield23', headerName: '', editable: false, field: 'flexfield23', width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield24', headerName: '', editable: false, field: 'flexfield24', width: 130, enableRowGroup: true,
    },
    {
        colId: 'flexfield25', headerName: '', editable: false, field: 'flexfield25', width: 130, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'flexfield26', headerName: '', editable: false, field: 'flexfield26', width: 200, enableRowGroup: true,
    },
    {
        colId: 'flexfield27', headerName: '', editable: false, field: 'flexfield27', width: 200, enableRowGroup: true, cellEditorPopup: true,
    },
    {
        colId: 'productId',
        headerName: 'Product ID',
        field: 'productId',
        width: 100,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.product && params.data.product[params.colDef.colId]) {
                renderText = params.data.product[params.colDef.colId];
            }
            return renderText;
        },
    },
    {
        colId: 'modelId',
        headerName: 'Model Id',
        field: 'modelId',
        width: 400,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.modelOffering && params.data.modelOffering[params.colDef.colId]) {
                renderText = params.data.modelOffering[params.colDef.colId];
            }
            return renderText;
        },
    },
    {
        colId: 'modelOfferingIdentifier',
        headerName: 'Model Offering Id',
        field: 'modelOfferingIdentifier',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.modelOffering && params.data.modelOffering[params.colDef.colId]) {
                renderText = params.data.modelOffering[params.colDef.colId];
            }
            return renderText;
        },
    },
    {
        colId: 'modelGroupDescription',
        headerName: 'Model Group Name',
        field: 'modelGroupDescription',
        width: 150,
        enableRowGroup: true,
        valueGetter: (params) => {
            let renderText = '';
            if (params.data && params.data.model && params.data.model.modelGroup && params.data.model.modelGroup.length > 0) {
                renderText = params.data.model.modelGroup.map((mg) => mg.modelGroupDescription).join(', ');
            }
            return renderText;
        },
    },
    {
        colId: 'leagueTeamAthlete', headerName: 'League/Team/Athlete', field: '', width: 180, enableRowGroup: true,
        valueGetter: (params) => {
			let renderText = [];
			
            if (params.data && params.data.product && params.data.product.leagueDescription) {
                renderText.push(params.data.product.leagueDescription);
            }
            if (params.data && params.data.product && params.data.product.teamName) {
                renderText.push(params.data.product.teamName);
            }
            if (params.data && params.data.product && params.data.product.athleteName) {
                renderText.push(params.data.product.athleteName);
            }
            return renderText.join('/');
        },
    },
    {
        colId: 'flexfield28', headerName: '', editable: false, field: 'flexfield28', width: 150, enableRowGroup: true, cellEditorPopup: true,
	},
    {
        colId: 'flexfield29', headerName: '', editable: false, field: 'flexfield29', width: 150, enableRowGroup: true, cellEditorPopup: true,
	},
    {
        colId: 'flexfield30', headerName: '', editable: false, field: 'flexfield30', width: 150, enableRowGroup: true, cellEditorPopup: true,
	},
    {
        colId: 'flexfield31', headerName: '', editable: false, field: 'flexfield31', width: 150, enableRowGroup: true, cellEditorPopup: true,
	},
    {
        colId: 'flexfield32', headerName: '', editable: false, field: 'flexfield32', width: 150, enableRowGroup: true, cellEditorPopup: true,
	},
    {
        colId: 'flexfield33', headerName: '', editable: false, field: 'flexfield33', width: 150, enableRowGroup: true, cellEditorPopup: true,
	},
    {
        colId: 'flexfield34', headerName: '', editable: false, field: 'flexfield34', width: 150, enableRowGroup: true, cellEditorPopup: true,
	},
    {
        colId: 'flexfield35', headerName: '', editable: false, field: 'flexfield35', width: 150, enableRowGroup: true, cellEditorPopup: true,
	},
    {
        colId: 'flexfield36', headerName: '', editable: false, field: 'flexfield36', width: 150, enableRowGroup: true, cellEditorPopup: true,
	},
    {
        colId: 'flexfield37', headerName: '', editable: false, field: 'flexfield37', width: 150, enableRowGroup: true, cellEditorPopup: true,
	},
    {
        colId: 'flexfield38', headerName: '', editable: false, field: 'flexfield38', width: 150, enableRowGroup: true, cellEditorPopup: true,
	},
	{
		colId: 'silo', headerName: 'Silo', field: 'silo', width: 180, enableRowGroup: true,
		valueGetter: (params) => {
			let renderText = '';
			
            if (params.data && params.data.product && params.data.product.siloDescription) {
                renderText = params.data.product.siloDescription;
            }
            return renderText;
        },
	},
	{
		colId: 'moGroupCollection', headerName: 'MO Group Collection', field: 'moGroupCollection', width: 180, enableRowGroup: true,
		valueGetter: (params) => {
			let renderText = [];
			
            if (params.data && params.data.modelOffering && params.data.modelOffering.moGroup && params.data.modelOffering.moGroup.length > 0) {
                renderText = params.data.modelOffering.moGroup.map((mg) => mg.modelOfferingGroupDescription).join(', ');
            }
            return renderText;
        },
	},
];
