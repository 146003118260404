import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';
import PATH from '../../../constants/routing';
import './index.scss';

class Header extends React.Component {
    render() {
        return (
            <div className="header">
                <div className="headerTop">
                    <a href="/" className="float-left logo">Home</a>
                    <h3 className="float-left">Nike By You</h3>
                    <div className="float-right user">{this.props.user.name}</div>
                    <div className="clearfix" />
                </div>
                <ul className="menuBar">
                    <li><NavLink to={PATH.PLAN} activeClassName={this.props.location.pathname === PATH.PLAN ? 'active' : ''}>Home</NavLink></li>
                    <li><NavLink to={PATH.HISTORY} activeClassName={this.props.location.pathname === PATH.HISTORY ? 'active' : ''}>change History</NavLink></li>
                    <li><NavLink to={PATH.CALENDAR_REPORT} activeClassName={this.props.location.pathname === PATH.CALENDAR_REPORT ? 'active' : ''}>launch calendar report</NavLink></li>
                    <li><NavLink to={PATH.PRODUCTION_REPORT} activeClassName={this.props.location.pathname === PATH.PRODUCTION_REPORT ? 'active' : ''}>digital production report</NavLink></li>
                </ul>
                <div className="clearfix" />
            </div>
        );
    }
}


Header.propTypes = {
    location: PropTypes.object.isRequired,
    user: PropTypes.shape({
        name: PropTypes.string,
    }),
};
export { Header };
export default withRouter(Header);
