import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

class Autocomplete extends Component {
    constructor(props) {
        super(props);
        const value = (props.value && props.value[0]) || null;
        let userInput = '';
        if (value) {
            userInput = value.label;
        }
        this.state = {
            // The active selection's index
            activeSuggestion: 0,
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            userInput,
            // selected option object
            value,
            elementFocus: false,
        };
    }

    componentDidUpdate = (oldProps, oldState) => {
        if ((oldProps.value !== this.props.value)) {
            const value = this.props.value || null;
            let userInput = '';
            if (value) {
                userInput = value.join('');
            }
            this.setState({ value, userInput });
        }
        if (oldState.value !== null && this.state.value === null && this.props.onChange) {
            this.props.onChange(this.state.value && this.state.value.length > 0 ? this.state.value.map((v) => v.value) : null);
        }
    }

    // Event fired when the input value is changed
    onChange = (e, val) => {
        const { suggestions } = this.props;
        const userInput = e.currentTarget.value;

        // Filter our suggestions that don't contain the user's input
        const filteredSuggestions = suggestions.filter((suggestion) => suggestion.label.toLowerCase().indexOf(userInput.toLowerCase()) > -1);

        // Update the user input and filtered suggestions, reset the active
        // suggestion and make sure the suggestions are shown
        const value = (e.currentTarget.value === '') ? null : val;
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions,
            showSuggestions: true,
            userInput: e.currentTarget.value,
            value,
        });
    };

    // Event fired when the user clicks on a suggestion
    onClick = (e) => {
        const { onChange } = this.props;
        // Update the user input and reset the rest of the state
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText,
            value: [this.props.suggestions.find((s) => s.value === e.currentTarget.getAttribute('rel'))],
        }, () => {
            if (onChange) {
                onChange(this.state.value && this.state.value.length > 0 ? this.state.value.map((v) => v.value) : null);
            }
        });
    };

    // Event fired when the user presses a key down
    onKeyDown = (e) => {
        const { activeSuggestion, filteredSuggestions } = this.state;
        const { onChange } = this.props;

        // User pressed the enter key, update the input and close the
        // suggestions
        if (e.keyCode === 13 && filteredSuggestions[activeSuggestion]) {
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion].label ? filteredSuggestions[activeSuggestion].label : '',
                value: filteredSuggestions[activeSuggestion] ? [filteredSuggestions[activeSuggestion]] : null,
            }, () => {
                if (onChange) {
                    onChange(this.state.value && this.state.value.length > 0 ? this.state.value.map((v) => v.value) : null);
                }
            });
        } else if (e.keyCode === 38) { // User pressed the up arrow, decrement the index
            if (activeSuggestion === 0) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion - 1 });
        } else if (e.keyCode === 40) { // User pressed the down arrow, increment the index
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion + 1 });
        } else if (e.keyCode === 27) { // User pressed Esc Key
            this.setState({
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion].label ? filteredSuggestions[activeSuggestion].label : '',
            });
        }
    };

    showSuggestions = () => {
        const { showSuggestionsOnFocus, suggestions } = this.props;

        if (showSuggestionsOnFocus) {
            this.setState({
                showSuggestions: true,
                filteredSuggestions: suggestions,
            });
        }
    }

    onBlur = () => {
        if (!this.state.elementFocus) {
            this.setState({ showSuggestions: false });
        }
    }

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInput,
                value,
            },
            props: {
                placeholder,
            },
        } = this;

        let suggestionsListComponent;

        if (showSuggestions) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions">
                        {filteredSuggestions.filter((f) => !value || value.indexOf(f.value) === -1).map((suggestion, index) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = 'suggestion-active';
                            }

                            return (
                                <li
                                    className={className}
                                    key={suggestion.value}
                                >
                                    <span
                                        onClick={onClick}
                                        tabIndex={index}
                                        onKeyDown={onClick}
                                        rel={suggestion.value}
                                        role="button"
                                        aria-label={suggestion.label}
                                    >
                                        {suggestion.label}
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No Options</em>
                    </div>
                );
            }
        }

        return (
            <div
                className="autocomplete"
                onBlur={this.onBlur}
                onMouseEnter={() => { this.setState({ elementFocus: true }); }}
                onMouseOver={() => { this.setState({ elementFocus: true }); }}
                onFocus={() => { this.setState({ elementFocus: true }); }}
                onMouseOut={() => { this.setState({ elementFocus: false }); }}
            >
                <input
                    type="text"
                    onChange={(e) => onChange(e, value)}
                    onClick={this.showSuggestions}
                    onKeyDown={onKeyDown}
                    value={userInput}
                    placeholder={placeholder}
                />
                {suggestionsListComponent}
            </div>
        );
    }
}
Autocomplete.defaultProps = {
    suggestions: [],
    placeholder: 'Search',
    showSuggestionsOnFocus: false,
};
Autocomplete.propTypes = {
    suggestions: PropTypes.arrayOf(PropTypes.shape(
        {
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        },
    )),
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    showSuggestionsOnFocus: PropTypes.bool,
};

export default Autocomplete;
