import { createReducer } from 'redux-act';
import * as a from '../actions/network';

const initialState = {
    activeRequestsCount: 0,
    messages: [],
};

export default createReducer({
    [a.IncrementRequestCount]: (state) => ({ ...state, activeRequestsCount: state.activeRequestsCount + 1 }),
    [a.DecrementRequestCount]: (state) => ({ ...state, activeRequestsCount: state.activeRequestsCount - 1 }),
    [a.AddAPIMessages]: (state, data) => {
        const messages = state.messages.slice();
        messages.push(data);
        return { ...state, messages };
    },
}, initialState);
