export default [
    {
        headerName: 'Action Date',
    },
    {
        headerName: 'Action',
    },
    {
        headerName: 'Week Beginning',
    },
    {
        headerName: 'Product Readiness',
    },
    {
        headerName: 'Model Name',
    },
    {
        headerName: 'Category PLM',
    },
    {
        headerName: 'Initiating PLM',
    },
    {
        headerName: 'Dev',
    },
    {
        headerName: 'Ops',
    },
    {
        headerName: 'Digital Owner',
    },
    {
        headerName: 'Nike By You Short Name',
    },
    {
        headerName: 'Product Code',
    },
    {
        headerName: 'Requested Launch Date',
    },
	{
		headerName: 'Silo'
	},
	{
		headerName: 'MO Group Collection'
	},
];
