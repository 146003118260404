import { createReducer } from 'redux-act';
import * as a from '../actions/planning';

const initialState = { data: [] };

export default createReducer({
    [a.setPlans]: (state, data) => ({ ...state, data }),
    [a.setUpdatedPlans]: (state, data) => {
        const planData = state.data.slice();
        data.forEach((plan) => {
            const index = planData.findIndex((row) => row.product.productId === plan.productId);
            if (index !== -1) {
                const updatedData = { ...planData[index], ...plan.newData, ...plan };
                planData.splice(index, 1, updatedData);
            }
        });
        return { ...state, data: planData };
    },
}, initialState);
